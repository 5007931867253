import { FC, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import {
  BsCheckCircleFill,
  BsFileEarmarkExcel,
  BsSearch,
  BsXCircleFill,
} from 'react-icons/bs'
import FService from '../../Services/Facturas.Services'
//import * as XLSX from 'xlsx'
import DTO1868Transportista from '../../DTO/DTO1868Transportista'
import FileManager from '../../../Utils/FileManager/FileManager'
import { IconContext } from 'react-icons'
import DTO1868SCACCAAT from '../../DTO/DTO1868SCACCAAT'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import xlsx from 'json-as-xlsx'

export interface IRptBrokerProps {}

export default function RptBroker(props: IRptBrokerProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Data, setData] = useState<DTO1868Transportista[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTO1868Transportista[]>([])
  const [ID, setID] = useState(0)
  const [Process, setProcess] = useState(9)
  const [show, setShowMsg] = useState(false)
  const [ModalFileManager, setModalFileManager] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-7))
  const [Fin, setFin] = useState(currentDate(0))
  const [modalFileManagerPDF, setModalFileManagerPDF] = useState(false)
  const [ModalData, setModalData] = useState(false)
  const [filtro, setFiltro] = useState('')
  const [SCAC, setSCAC] = useState('')
  const [CAAT, setCAAT] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const columnsConcepts = [
    {
      name: 'Factura',
      width: '75px',
      center: true,
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idpdf, 8)
          }}
        >
          <IconContext.Provider
            value={{ color: row.idpdf > 0 ? 'green' : 'red', size: '20px' }}
          >
            {row.idpdf > 0 ? <BsCheckCircleFill /> : <BsXCircleFill />}
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Prefile',
      width: '75px',
      center: true,
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            if (row.idPrefile > 0) {
              showPDF(row.idPrefile, 24)
            } else {
              setID(row.id)
              setProcess(24)
              setModalFileManager(true)
            }
          }}
        >
          <IconContext.Provider
            value={{ color: row.idPrefile > 0 ? 'green' : 'red', size: '20px' }}
          >
            {row.idPrefile > 0 ? <BsCheckCircleFill /> : <BsXCircleFill />}
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Referencia',
      width: '110px',
      selector: (row: DTO1868Transportista) => row.referencia,
      sortable: true,
    },
    {
      name: 'Remesa',
      width: '110px',
      selector: (row: DTO1868Transportista) => row.remesa,
      sortable: true,
    },
    {
      name: 'SCAC',
      width: '80px',
      selector: (row: DTO1868Transportista) => row.scac,
      sortable: true,
    },
    {
      name: 'CAAT',
      width: '80px',
      selector: (row: DTO1868Transportista) => row.caat,
      sortable: true,
    },
    {
      name: 'TAX ID',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.numRegIdTrib,
      sortable: true,
    },
    {
      name: 'Cliente',
      width: '250px',
      selector: (row: DTO1868Transportista) => row.cliente,
      sortable: true,
    },
    {
      name: 'Responsable cruce',
      width: '250px',
      selector: (row: DTO1868Transportista) => row.responsableCruce,
      sortable: true,
    },
    {
      name: 'Sello',
      width: '120px',
      selector: (row: DTO1868Transportista) => (row.sello ? row.sello : ''),
      sortable: true,
    },
    {
      name: 'Placas',
      width: '120px',
      selector: (row: DTO1868Transportista) => row.placas,
      sortable: true,
    },
    {
      name: 'No Caja',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.ruta,
      sortable: true,
    },
    {
      name: 'Fec Cruce',
      width: '100px',
      selector: (row: DTO1868Transportista) =>
        row.fechaCruce ? row.fechaCruce.substring(1, 10) : '',
      sortable: true,
    },
    {
      name: 'Pallets',
      width: '85px',
      center: true,
      selector: (row: DTO1868Transportista) => row.pallets,
      sortable: true,
    },
    {
      name: 'Peso Bruto',
      width: '120px',
      selector: (row: DTO1868Transportista) =>
        row.pesoBruto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      sortable: true,
    },
    {
      name: 'Aduana',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.aduana,
      sortable: true,
    },
  ]

  const conditionalRowStyles = [
    {
      when: (row: DTO1868Transportista) => row.urgente == 1,
      style: {
        backgroundColor: '#F9FC87',
        color: '#000000',
        '&:hover': {
          cursor: 'wait',
        },
      },
    },
  ]

  const showPDF = (id: number, Proceso: number) => {
    FService.getFileContent(id, Proceso)
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  function exportExcel(
    jsonData: DTO1868Transportista[],
    fileName: string
  ): void {
    const dataOnly = jsonData.map(
      ({
        fechaCruce,
        scac,
        caat,
        numRegIdTrib,
        responsableCruce,
        sello,
        placas,
        ruta,
        pallets,
        pesoBruto,
        aduana,
      }) => {
        return {
          fcruce: { fechaCruce },
          scac,
          caat,
          numRegIdTrib,
          responsableCruce,
          sello,
          placas,
          ruta,
          pallets,
          pesoBruto,
          aduana,
        }
      }
    )
    let data = [
      {
        sheet: 'Facturas',
        columns: [
          { label: 'SCAC', value: 'scac' },
          { label: 'CAAT', value: 'caat' },
          { label: 'TAX ID', value: 'numRegIdTrib' },
          { label: 'Responsable cruce', value: 'responsableCruce' },
          { label: 'Sello', value: 'sello' },
          { label: 'Placas', value: 'placas' },
          { label: 'No Caja', value: 'ruta' },
          {
            label: 'Fecha Cruce',
            value: (row: any) =>
              row.fcruce.fechaCruce
                ? row.fcruce.fechaCruce.substring(0, 10)
                : '',
          },
          { label: 'Pallets', value: 'pallets' },
          { label: 'Peso Bruto', value: 'pesoBruto' },
          { label: 'Aduana', value: 'aduana' },
        ],
        content: dataOnly,
      },
    ]
    let settings = {
      fileName,
      extraLength: 3,
      writeOptions: {},
      RTL: false,
    }
    xlsx(data, settings)
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const generaReporte = () => {
    FService.getRptTransportista(Inicio, Fin, UserId, Perfil, 1)
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    let value = e.target.value
    value = value.toUpperCase()
    setFiltro(value)
    if (value.length > 0) {
      setData(
        Data.filter(function (row: DTO1868Transportista) {
          return (
            row.responsableCruce.includes(value) ||
            row.pallets.toString().includes(value) ||
            row.pesoBruto.toString().includes(value) ||
            row.carga.includes(value) ||
            row.tipoPedimento.includes(value) ||
            row.aduana.includes(value) ||
            row.fraccionArancelaria.includes(value)
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas de Zinc Internacional')
  }

  const uploadPDF = (idFile: number, Process: number) => {
    FService.Append(idFile, Process)
      .then((response) => {
        if (!response.data) {
          setHeader('Error')
          setMsg('Ocurrio un error, no se guardo correctamente el archivo')
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveSCACCAAT = () => {
    const data: DTO1868SCACCAAT = {
      id: ID,
      scac: SCAC,
      caat: CAAT,
      fechaCruce: new Date(),
      folioCartaPorte: '',
    }
    FService.AppendSCACCAAT(data)
      .then((response) => {
        generaReporte()
        setHeader('Informativo')
        setMsg('La informacion se guardo existosamente')
        setModalData(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setModalData(false)
        return
      })
  }

  const uploadFileByProcess = (idFile: number, Process: number) => {
    FService.RelateByProcess(ID, idFile, Process)
      .then((response) => {
        if (!response.data) {
          setHeader('Error')
          setMsg('Ocurrio un error, no se guardo correctamente el archivo')
          setShowMsg(true)
        }
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-2'></div>
            <div className='col-md-3'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </div>
            <div className='col'></div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        {
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            striped={true}
            dense={true}
            paginationPerPage={18}
            pagination={true}
            highlightOnHover
            columns={columnsConcepts}
            conditionalRowStyles={conditionalRowStyles}
            data={Data}
            pointerOnHover
          />
        }
      </div>

      <Modal
        show={modalFileManagerPDF}
        onHide={() => setModalFileManagerPDF(false)}
        size='lg'
      >
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={Process}
            IdFile={ID}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['pdf', 'PDF']}
            Leyenda={
              'Estimado colaborador, favor de seleccionar el archivo PDF, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadPDF(idFile, Process)
            }}
            onDeleteFM={function (IDMaster: number, Proceso: number): void {}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant='secondary'
                onClick={() => setModalFileManagerPDF(false)}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal show={ModalData} onHide={() => setModalData(false)} size='lg'>
        <Row style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Col xs={2} style={{ textAlign: 'right' }}>
            <Button
              variant='secondary'
              onClick={() => setModalData(false)}
              size='sm'
            >
              Cerrar
            </Button>
          </Col>
          <Col xs={1}>{ID}</Col>
          <Col xs={1}>SCAC</Col>
          <Col xs={2}>
            <Form.Control
              type='text'
              id='SCAC'
              value={SCAC}
              size='sm'
              onChange={(e) => {
                setSCAC(e.target.value)
              }}
            />
          </Col>
          <Col xs={1}>CAAT</Col>
          <Col xs={2}>
            <Form.Control
              type='text'
              id='CAAT'
              value={CAAT}
              size='sm'
              onChange={(e) => {
                setCAAT(e.target.value)
              }}
            />
          </Col>
          <Col xs={1}></Col>
          <Col xs={2}>
            <Button variant='primary' onClick={() => saveSCACCAAT()} size='sm'>
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        show={ModalFileManager}
        onHide={() => setModalFileManager(false)}
        size='lg'
      >
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={Process}
            IdFile={ID}
            IDUser={UserId}
            FileName={''}
            canDelete={true}
            FileType={['pdf', 'PDF']}
            Leyenda={`Estimado colaborador, favor de seleccionar el archivo Prefile arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...`}
            onAppendFM={function (idFile: number): void {
              uploadFileByProcess(idFile, Process)
            }}
            onDeleteFM={function (IDMaster: number, Proceso: number): void {}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant='secondary'
                onClick={() => setModalFileManager(false)}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from 'react-bootstrap'
import IConfigEmails from '../Interfaces/IConfigEmails'
import ConfigDS from '../Services/Config.Services'
import CatBrokerDS from '../Services/CatBrokers.Services'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import ICatBroker from '../Interfaces/ICatBroker'
import { IconContext } from 'react-icons'
import { AiFillCloseCircle, AiFillSave } from 'react-icons/ai'
import ICatBrokersCorreo from '../Interfaces/ICatBrokersCorreo'
import { BsFillPencilFill } from 'react-icons/bs'
import DataTable from 'react-data-table-component'

export interface IConfigProps {}

export default function Config(props: IConfigProps) {
  const [NotificacionAA, setNotificacionAA] = useState('')
  const [NotificacionZINCDirectos, setNotificacionZINCDirectos] = useState('')
  const [NotificacionZINCTransbordos, setNotificacionZINCTransbordos] =
    useState('')
  const [NotificacionBroker, setNotificacionBroker] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [IdBroker, setIdBroker] = useState<number>(1)
  const [IdCorreoBroker, setIdCorreoBroker] = useState(0)
  const [CorreoBroker, setCorreoBroker] = useState('')
  const [CatBrokers, setCatBrokers] = useState<ICatBroker[]>([])
  const [CatBrokersCorreos, setCatBrokersCorreos] = useState<
    ICatBrokersCorreo[]
  >([])
  const [ModalDeleteEmail, setModalDeleteEmail] = useState(false)

  const colData = [
    {
      name: 'id',
      width: '60px',
      selector: (row: ICatBrokersCorreo) => row.id,
      sortable: true,
    },
    {
      name: 'Correo',
      width: '60%',
      selector: (row: ICatBrokersCorreo) => row.correo,
    },
    {
      name: 'Edita',
      cell: (row: ICatBrokersCorreo) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIdCorreoBroker(row.id)
            setCorreoBroker(row.correo)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Elimina',
      cell: (row: ICatBrokersCorreo) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIdCorreoBroker(row.id)
            setCorreoBroker(row.correo)
            setModalDeleteEmail(true)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <AiFillCloseCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const loadData = () => {
    ConfigDS.EmailNotifyGET()
      .then((response) => {
        console.log(response.data)
        setNotificacionAA(response.data.notificacionAA)
        setNotificacionZINCDirectos(response.data.notificacionZINCDirectos)
        setNotificacionZINCTransbordos(
          response.data.notificacionZINCTransbordos
        )
        setNotificacionBroker(response.data.notificacionBroker)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatBrokers = () => {
    CatBrokerDS.Get()
      .then((response) => {
        setCatBrokers(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatBrokersCorreos = () => {
    CatBrokerDS.CorreosGet()
      .then((response) => {
        console.log(response.data)
        setCatBrokersCorreos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveData = () => {
    const data: IConfigEmails = {
      id: 1,
      notificacionAA: NotificacionAA,
      notificacionZINCDirectos: NotificacionZINCDirectos,
      notificacionZINCTransbordos: NotificacionZINCTransbordos,
      notificacionBroker: NotificacionBroker,
    }
    ConfigDS.EmailNotifyUpdate(data)
      .then((response) => {
        setHeader('Informativo')
        setMsg('La informacion se guardo correctamente')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const saveBrokerEmail = () => {
    const data: ICatBrokersCorreo = {
      id: IdCorreoBroker,
      idBroker: IdBroker,
      correo: CorreoBroker,
    }
    CatBrokerDS.CorreosAppend(data)
      .then((response) => {
        loadCatBrokersCorreos()
        setIdCorreoBroker(0)
        setCorreoBroker('')
        setHeader('Informativo')
        setMsg('La informacion se guardo correctamente')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error')
        setShowMsg(true)
        return
      })
  }

  const loadEverything = () => {
    loadData()
    loadCatBrokers()
    loadCatBrokersCorreos()
  }

  useEffect(() => {
    if (IdBroker > 0) loadEverything()
  }, [IdBroker])

  const deleteEmail = () => {
    CatBrokerDS.CorreoDelete(IdCorreoBroker)
      .then((response) => {
        setIdCorreoBroker(0)
        setCorreoBroker('')
        setModalDeleteEmail(false)
        loadCatBrokersCorreos()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Row style={{ paddingTop: '50px' }}>
        <Col xs={4} style={{ paddingLeft: '20px' }}>
          <Card style={{ textAlign: 'left' }}>
            <Card.Body>
              <Card.Header style={{ fontWeight: 'bold' }}>
                Configure las notificaciones
              </Card.Header>
              <Card.Text>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Correos de GEMCO'
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        value={NotificacionAA}
                        size='sm'
                        onChange={(e) => {
                          setNotificacionAA(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Correos de ZINC directos'
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        value={NotificacionZINCDirectos}
                        size='sm'
                        onChange={(e) => {
                          setNotificacionZINCDirectos(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Correos de ZINC transbordos'
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        value={NotificacionZINCTransbordos}
                        size='sm'
                        onChange={(e) => {
                          setNotificacionZINCTransbordos(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Correos del Broker'
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        value={NotificacionBroker}
                        size='sm'
                        onChange={(e) => {
                          setNotificacionBroker(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Card.Text>
              <Row>
                <Col xs={9}></Col>
                <Col xs={3}>
                  <Button
                    variant='primary'
                    onClick={() => {
                      saveData()
                    }}
                  >
                    Guarda
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4}>&nbsp;</Col>
        <Col xs={4} style={{ paddingRight: '20px' }}>
          <Card style={{ textAlign: 'left' }}>
            <Card.Body>
              <Card.Header style={{ fontWeight: 'bold' }}>Brokers</Card.Header>
            </Card.Body>
            <Row style={{ padding: '10px' }}>
              <Col xs={3} style={{ paddingTop: '15px' }}>
                <Form.Select
                  size='sm'
                  onChange={(e: any): void => {
                    setIdBroker(e.target.value)
                  }}
                  value={IdBroker}
                >
                  {CatBrokers &&
                    CatBrokers.map((item) => {
                      return <option value={item.id}>{item.broker}</option>
                    })}
                </Form.Select>
              </Col>
              <Col xs={8}>
                <FloatingLabel
                  controlId='floatingInput'
                  label='Correo'
                  className='mb-3'
                >
                  <Form.Control
                    type='email'
                    placeholder='correo@example.com'
                    onChange={(e: any): void => {
                      setCorreoBroker(e.target.value)
                    }}
                    value={CorreoBroker}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={1} style={{ paddingTop: '15px' }}>
                <div
                  title='De un click aqui para guardar nuevo correo'
                  onClick={() => {
                    saveBrokerEmail()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <IconContext.Provider
                    value={{ color: '#002f87', size: '30px' }}
                  >
                    <AiFillSave />
                  </IconContext.Provider>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DataTable
                  noHeader
                  defaultSortFieldId={'id'}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  columns={colData}
                  data={CatBrokersCorreos.filter(
                    (correo) => correo.idBroker == IdBroker
                  ).map((correo) => {
                    return correo
                  })}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        show={ModalDeleteEmail}
        onHide={() => setModalDeleteEmail(false)}
        size='lg'
      >
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Alert variant='warning' style={{ textAlign: 'center' }}>
                      <h6>
                        Esta seguro de eliminar este correo: {CorreoBroker}?
                        <br />
                      </h6>
                    </Alert>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <Col></Col>
                  <Col xs={1}>
                    <Button
                      variant='secondary'
                      style={{ textAlign: 'right' }}
                      onClick={() => {
                        setModalDeleteEmail(false)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                  <Col xs={8}></Col>
                  <Col xs={2}>
                    <Button
                      variant='danger'
                      onClick={() => {
                        deleteEmail()
                      }}
                    >
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import http from '../../../Services/common/http-common'
import I1868CatDestinos from '../Interfaces/I1868CatDestinos'
import ICatDestinoCorreo from '../Interfaces/ICatDestinoCorreo'

class CatDestinos {
  Get() {
    return http.get<I1868CatDestinos[]>(`/CatDestinos/Get`)
  }

  appendCatDestino(data: I1868CatDestinos) {
    return http.post<I1868CatDestinos>(`/CatDestinos/Append`, data)
  }

  CorreosGet() {
    return http.get<ICatDestinoCorreo[]>(`/CatDestinos/Correos/Get`)
  }

  CorreoAppend(data: ICatDestinoCorreo) {
    return http.post<ICatDestinoCorreo>(`/CatDestinos/Correo/Append`, data)
  }
}
export default new CatDestinos()

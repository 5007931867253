import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import {
  BsCheckCircleFill,
  BsFileEarmarkExcel,
  BsSearch,
  BsXCircleFill,
} from 'react-icons/bs'
import I1868FacturaDetalle from '../../Interfaces/I1868FacturaDetalle'
import DTO1868ReporteFactura from '../../DTO/DTO1868ReporteFactura'
import DTO1868Clientes from '../../DTO/DTO1868Clientes'
import FService from '../../Services/Facturas.Services'
import DSCatDestinos from '../../Services/CatDestinos.Services'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import '../../../../css/zincinternacional.css'
import RptViajesTerminadosDetalle from './RptViajesTerminadosDetalle'
import { RootState } from '../../../../store/store'
import {
  populateFacturaDetalle,
  toggleRow,
} from '../../../../store/features/Clientes/1868/Data1868FacturaDetalleSlice'
import { populateCatClaveSAT } from '../../../../store/features/Clientes/1868/Cat1868ClaveSATSlice'
import { populateCatDestinos } from '../../../../store/features/Clientes/1868/Cat1868DestinosSlice'
import { populateCatResponsables } from '../../../../store/features/Clientes/1868/Cat1868ResponsablesSlice'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'

export interface IRptViajesTerminadosProps {}

export default function RptViajesTerminados(props: IRptViajesTerminadosProps) {
  const dispatch = useDispatch()
  const mCatDestinos = useSelector(
    (state: RootState) => state.Cat1868Destinos.Cat1868Destinos
  )
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Cliente, setCliente] = useState('')
  const [Clientes, setClientes] = useState<DTO1868Clientes[]>([])
  const [Data, setData] = useState<DTO1868ReporteFactura[]>([])
  const [DataDetalle, setDataDetalle] = useState<I1868FacturaDetalle[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTO1868ReporteFactura[]>([])
  const [ID, setID] = useState(0)
  const [IDDestino, setIDDestino] = useState(0)
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [tipoReporte, setTipoReporte] = useState(0)
  const [msgColor, setMsgColor] = useState('primary')
  const [Proceso, setProceso] = useState(0)
  const [ModalFileManager, setModalFileManager] = useState(false)
  const [Entregar, setEntregar] = useState('')

  const showPDF = (id: number) => {
    FService.getFileContent(id, 8)
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  const showXML = (id: number, Invoice: string) => {
    FService.getFileContent(id, 7)
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/xml' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado XML')
        setShowMsg(true)
        return
      })
  }

  function exportExcel(
    jsonData: DTO1868ReporteFactura[],
    fileName: string
  ): void {
    let Heading = [
      [
        'Fecha factura',
        'Invoice',
        'Ruta',
        'Cliente',
        'Pedido cliente',
        'Transportista',
        'Responsable cruce',
        'Sellos',
        'Placas',
        'Pallets',
        'Carga',
        'Tipo pedimento',
        'Aduana',
        'Fecha Cruce',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        fechaFactura,
        invoice,
        ruta,
        cliente,
        pedidoCliente,
        transportista,
        responsableCruce,
        sello,
        placas,
        pallets,
        carga,
        tipoPedimento,
        aduana,
        fechaCruce,
      }) => {
        return {
          fechaFactura,
          invoice,
          ruta,
          cliente,
          pedidoCliente,
          transportista,
          responsableCruce,
          sello,
          placas,
          pallets,
          carga,
          tipoPedimento,
          aduana,
          fechaCruce,
        }
      }
    )
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, dataOnly, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c + 1; C <= range.e.c + 1; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const generaReporte = () => {
    setTipoReporte(0)
    FService.getAll(Inicio, Fin, UserId, Perfil, 2)
      .then((response) => {
        loadCustomers()
        loadCatDestinos()
        setData(response.data)
        setDataOriginal(response.data)
        FService.getAllDetail(Inicio, Fin)
          .then((responsed) => {
            setDataDetalle(responsed.data)
            dispatch(populateFacturaDetalle(responsed.data))
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    let value = e.target.value
    value = value.toUpperCase()
    setFiltro(value)
    if (value.length > 0) {
      setData(
        Data.filter(function (row: DTO1868ReporteFactura) {
          return (
            row.fechaFactura.includes(value) ||
            row.invoice.includes(value) ||
            row.ruta.includes(value) ||
            row.cliente.includes(value) ||
            row.pedidoCliente.includes(value) ||
            row.transportista.includes(value) ||
            row.responsableCruce.includes(value) ||
            row.carga.includes(value) ||
            row.tipoPedimento.includes(value) ||
            row.aduana.includes(value)
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  const loadCustomers = () => {
    setTipoReporte(0)
    FService.getClientes()
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatClaveSAT = () => {
    FService.getCatClaveSAT()
      .then((response) => {
        dispatch(populateCatClaveSAT(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatDestinos = () => {
    DSCatDestinos.Get()
      .then((response) => {
        dispatch(populateCatDestinos(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatResponsables = () => {
    FService.getCatResponsables()
      .then((response) => {
        dispatch(populateCatResponsables(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas de Zinc Internacional')
  }

  useEffect(() => {
    loadCustomers()
    loadCatClaveSAT()
    loadCatDestinos()
    loadCatResponsables()
  }, [])

  useEffect(() => {
    mCatDestinos.filter((a) => {
      if (a.id == IDDestino) {
        setEntregar(a.direccion)
        return a
      }
    })
  }, [IDDestino])

  useEffect(() => {
    if (Cliente.includes('SELECCIONE')) setData(DataOriginal)
    else
      setData(
        DataOriginal.filter(function (row: DTO1868ReporteFactura) {
          return row.cliente === Cliente
        })
      )
  }, [Cliente])

  const changeToggle = (id: number) => {
    dispatch(toggleRow(id))
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-2'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(e.target.value)
                }}
                className='form-select form-select-sm'
              >
                {Clientes
                  ? Clientes.map((a) => {
                      return (
                        <option value={a.cliente} key={a.cliente}>
                          {a.cliente}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-3'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </div>
            <div className='col'></div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='MDcontainer'>
        <Table className='MDTable' hover>
          <thead style={{ position: 'sticky', top: '0' }}>
            <tr>
              <th style={{ width: '50px', textAlign: 'center' }}>PDF</th>
              <th style={{ width: '50px', textAlign: 'center' }}>XML</th>
              <th style={{ width: '50px', textAlign: 'center' }}>DODA</th>
              <th style={{ width: '50px', textAlign: 'center' }}>Prefile</th>
              <th style={{ width: '50px', textAlign: 'center' }}>BL</th>
              <th style={{ width: '100px', textAlign: 'center' }}>
                Fecha factura
              </th>
              <th style={{ width: '100px' }}>Invoice #</th>
              <th style={{ width: '70px' }}>Ruta</th>
              <th style={{ width: '270px' }}>Cliente</th>
              <th style={{ width: '120px' }}>Pedido cliente</th>
              <th style={{ width: '270px' }}>Transportista</th>
              <th style={{ width: '270px' }}>Responsable cruce</th>
              <th style={{ width: '100px' }}>SCAC</th>
              <th style={{ width: '100px' }}>CAAT</th>
              <th style={{ width: '150px' }}>Sello</th>
              <th style={{ width: '150px' }}>Placas</th>
              <th style={{ width: '150px' }}>Carga</th>
              <th style={{ width: '100px' }}>Tipo pedimento</th>
              <th style={{ width: '550px' }}>Entregar en</th>
              <th style={{ width: '150px' }}>Aduana</th>
              <th style={{ width: '150px' }}>Cruce</th>
            </tr>
          </thead>
          <tbody>
            {Data
              ? Data.map((MasterData) => {
                  return (
                    <>
                      <tr
                        key={MasterData.id}
                        onClick={() => {
                          changeToggle(MasterData.id)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>
                          {MasterData.idpdf > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idpdf)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'green', size: '20px' }}
                              >
                                <BsCheckCircleFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                            >
                              <IconContext.Provider
                                value={{ color: 'red', size: '20px' }}
                              >
                                <BsXCircleFill />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>
                        <td>
                          <div
                            style={{ textAlign: 'center' }}
                            onClick={() => {
                              showXML(MasterData.idxml, MasterData.invoice)
                            }}
                          >
                            <IconContext.Provider
                              value={{ color: 'green', size: '20px' }}
                            >
                              <BsCheckCircleFill />
                            </IconContext.Provider>
                          </div>
                        </td>
                        <td>
                          {MasterData.idDoda > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idDoda)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'green', size: '20px' }}
                              >
                                <BsCheckCircleFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                setID(MasterData.id)
                                setProceso(23)
                                setModalFileManager(true)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'red', size: '20px' }}
                              >
                                <BsXCircleFill />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>
                        <td>
                          {MasterData.idPrefile > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idPrefile)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'green', size: '20px' }}
                              >
                                <BsCheckCircleFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                // downloadFile(MasterData.idPrefile, 24)
                                setID(MasterData.id)
                                setProceso(24)
                                setModalFileManager(true)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'red', size: '20px' }}
                              >
                                <BsXCircleFill />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>
                        <td>
                          {MasterData.idbl > 0 ? (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                showPDF(MasterData.idbl)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'green', size: '20px' }}
                              >
                                <BsCheckCircleFill />
                              </IconContext.Provider>
                            </div>
                          ) : (
                            <div
                              style={{ textAlign: 'center', cursor: 'pointer' }}
                              onClick={() => {
                                setID(MasterData.id)
                                setProceso(29)
                                setModalFileManager(true)
                              }}
                            >
                              <IconContext.Provider
                                value={{ color: 'red', size: '20px' }}
                              >
                                <BsXCircleFill />
                              </IconContext.Provider>
                            </div>
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {MasterData.fechaFactura
                            ? MasterData.fechaFactura.substring(0, 10)
                            : ''}
                        </td>
                        <td style={{}}>{MasterData.invoice}</td>
                        <td style={{}}>{MasterData.ruta}</td>
                        <td style={{}}>{MasterData.cliente}</td>
                        <td style={{}}>{MasterData.pedidoCliente}</td>
                        <td style={{}}>{MasterData.transportista}</td>
                        <td style={{}}>{MasterData.responsableCruce}</td>
                        <td style={{}}>{MasterData.scac}</td>
                        <td style={{}}>{MasterData.caat}</td>
                        <td>{MasterData.sello}</td>
                        <td>{MasterData.placas}</td>
                        <td>{MasterData.carga}</td>
                        <td>{MasterData.tipoPedimento}</td>
                        <td>{MasterData.entregar}</td>
                        <td>{MasterData.aduana}</td>
                        <td>{MasterData.fhTerminacion}</td>
                      </tr>
                      <tr style={{ height: '0px' }}>
                        <th
                          colSpan={2}
                          style={{ backgroundColor: '#F8F9FE', height: '0px' }}
                        ></th>
                        <th
                          colSpan={14}
                          style={{ backgroundColor: '#F8F9FE', height: '0px' }}
                        >
                          <RptViajesTerminadosDetalle
                            DataMaster={MasterData}
                            key={MasterData.id}
                          />
                        </th>
                      </tr>
                    </>
                  )
                })
              : null}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col xs={11}></Col>
        <Col style={{ fontWeight: 'bold' }}>Total: {Data.length}</Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

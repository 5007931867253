import React, { FC, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import DashboardCliente from './DashboardCliente'


interface IProps {}

export default function Dashboard(props: IProps) {
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })

  return (
    <div>
      <br />
      <Container>
        <Row xs={1} md={3} className='g-4'>
        {[['CLIENTE: Zinc Internacional', 'Administrador'].includes(Perfil) ? <DashboardCliente /> : ''].map((item) => {
            return <React.Fragment>{item}</React.Fragment>
          })}
        </Row>
      </Container>
    </div>
  )
}

import * as React from 'react'
import { Formik, Form, Field } from 'formik'
import '../../css/generic01.css'
import * as Yup from 'yup'
import { IRegister } from '../../Interfaces/IRegister'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Form as Frm,
  Modal,
  Tab,
  Table,
  Tabs,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import IPerfilesMenu from '../../Interfaces/IPerfilesMenu'
import { IPerfil } from '../../Interfaces/Catalogos/IPerfiles'
import PerfilesMenuServices from '../../Services/Catalogos/PerfilesMenu.Services'
import ClientesServices from '../../Services/Catalogos/Clientes.Services'
import usuariosServices from '../../Services/Catalogos/Usuarios.Services'
import MenuUsuario from '../MenuUsuario/MenuUsuario'
import IClientes from '../../Interfaces/Catalogos/IClientes'
import DTOUsuarioCliente from '../../DTO/DTOUsuarioCliente'
import ITransportistas from '../../Interfaces/Catalogos/ITransportitas'
import IProveedores from '../../Interfaces/Catalogos/IProveedores'
import DTOasignaClienteProveedor from '../../DTO/DTOasignaClienteProveedor'
import DTOClienteTransportista from '../../DTO/DTOClienteTransportita'
import ITokenInfo from '../../Interfaces/ITokenInfo'
import { UserList } from './UserList/UserList'
import DTOUsuariosShort from '../../DTO/DTOUsuariosShort'
import DTOClonarUsuario from '../../DTO/DTOClonarUsuario'
import { AdmonPerfiles } from './admonPerfiles/AdmonPerfiles'
import { Form as FormCtrl } from 'react-bootstrap'

interface IProps {}

const SignupSchema = Yup.object().shape({
  usuario: Yup.string()
    .min(6, 'Usuario demasiado corto')
    .max(50, 'Usuario demasiado largo')
    .required('Requerido'),
  nombre: Yup.string()
    .min(6, 'Nombre de usuario demasiado corto')
    .max(100, 'Nombre de usuario demasiado largo')
    .required('Requerido'),
  contrasena: Yup.string()
    .min(6, 'Contraseña demasiado corta')
    .max(50, 'Contraseña demasiado larga'),
  correo: Yup.string().email('Correo invalido').required('Requirido'),
})

export default function Register(props: IProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [showDialog, setShowDialog] = React.useState(false)
  const [modalUserList, setModalUserList] = React.useState(false)
  const [modalAdmonPerfiles, setmodalAdmonPerfiles] = React.useState(false)
  const [msgDialog, setmsgDialog] = React.useState('')
  const [Asignaciones, setAsignacion] = useState(false)
  const [IdUsuario, setIdUsuario] = useState(0)
  const [Usuario, setUsuario] = useState('')
  const [Nombre, setNombre] = useState('')
  const [Contrasena, setContrasena] = useState('')
  const [Correo, setCorreo] = useState('')
  const [TipoUsuario, setTipoUsuario] = useState(2)
  const [Activo, setActivo] = useState(1)
  const [FechaAlta, setFechaAlta] = useState('')
  const [UsuarioAlta, setUsuarioAlta] = useState(0)
  const [FechaModifico, setFechaModifico] = useState('')
  const [UsuarioModifico, setUsuarioModifico] = useState(0)
  const [FechaElimino, setFechaElimino] = useState('')
  const [UsuarioElimino, setUsuarioElimino] = useState(0)
  const [MotivoElimino, setMotivoelimino] = useState('')
  const [IdModulo, setIdModulo] = useState(0)
  const [Dept, setDept] = useState(0)
  const [TMercancia, setTMercancia] = useState(0)
  const [FechaUltimaVisita, setFechaUltimaVisita] = useState('')
  const [Visitas, setVisitas] = useState(0)
  const [Internos, setInternos] = useState(0)
  const [PermisoEspecial, setPermisoEspecial] = useState(0)
  const [EstadoConfiguracion, setEstadoConfiguracion] = useState(0)
  const [FechaValidacionConf, setFechaValidacionConf] = useState('')
  const [RealizoEncuesta, setRealizoEncuesta] = useState('')
  const [EncuestaActiva, setEncuestaActiva] = useState(0)
  const [FechaLimiteEncuesta, setFechaLimiteEnuesta] = useState('')
  const [CodigoAccesoM, setCodigoAccesoM] = useState('')
  const [TokenAccesoM, setTokenAccesoM] = useState('')
  const [DeviceToken, setDeviceToken] = useState('')
  const [IdPerfil, setIdPerfil] = useState(0)
  const [user2Clone, setUser2Clone] = React.useState(0)
  const [perfiles, setPerfiles] = useState<IPerfil[]>([
    {
      id: 0,
      perfil: '-SELECCIONE-',
    },
  ])
  const [clientes, setClientes] = useState<IClientes[]>([])
  const [transportistas, setTransportistas] = useState<ITransportistas[]>([])
  const [proveedores, setProveedores] = useState<IProveedores[]>([])
  const [comboUsuarios, setcomboUsuarios] = useState<DTOUsuariosShort[]>([])
  const [toastPerfil, setToastPerfil] = useState(false)
  const [UsuariosPerfilesMenu, setUsuariosPerfilesMenu] =
    useState<IPerfilesMenu[]>()
  const [perfilActual, setPerfilActual] = useState<IPerfilesMenu[]>()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [usuarioDestino, setUsuarioDestino] = useState('')
  const [Search, setSearch] = useState('')
  var initialValues: IRegister = {
    id: IdUsuario,
    usuario: Usuario,
    nombre: Nombre,
    contrasena: Contrasena,
    correo: Correo,
    tipoUsuario: TipoUsuario,
    activo: Activo,
    fechaAlta: currentDate(),
    usuarioAlta: IdUsuario,
    fechaModifico: currentDate(),
    usuarioModifico: UsuarioModifico,
    fechaElimino: FechaElimino,
    usuarioElimino: 0,
    motivoElimino: MotivoElimino,
    idModulo: IdModulo,
    dept: Dept,
    tmercancia: TMercancia,
    fechaUltimaVisita: FechaUltimaVisita,
    visitas: Visitas,
    internos: Internos,
    permisoEspecial: PermisoEspecial,
    estadoConfiguracion: EstadoConfiguracion,
    fechaValidacionConf: FechaValidacionConf,
    realizoEncuesta: RealizoEncuesta,
    encuestaActiva: EncuestaActiva,
    fechaLimiteEncuesta: FechaLimiteEncuesta,
    codigoAccesoM: CodigoAccesoM,
    tokenAccesoM: TokenAccesoM,
    deviceToken: DeviceToken,
    idPerfil: IdPerfil,
  }

  const initialValuesPerfiles: IPerfil = {
    id: IdPerfil,
    perfil: '',
  }

  function getUsuario() {
    const item = localStorage.getItem('tokenInfo')
    let Usr = ''
    if (item) {
      const registro: ITokenInfo = JSON.parse(item)
      Usr = registro.Usuario
    }
    return Usr
  }

  /*   useEffect(() => {
    if (!isNaN(IdUsuario)) {
      PerfilesMenuServices.getAllPerfilesMenu()
        .then((response) => {
          setUsuariosPerfilesMenu(response.data)
        })
        .catch((e: Error) => {   })
    }
  }, []) */

  useEffect(() => {
    if (!isNaN(IdUsuario)) {
      PerfilesMenuServices.getAllPerfiles()
        .then((response) => {
          setPerfiles(response.data)
        })
        .catch((e: Error) => {})
    }
  }, [])

  useEffect(() => {
    usuariosServices
      .getAllUsuariosShort()
      .then((response) => {
        setcomboUsuarios(response.data)
      })
      .catch((e: Error) => {})
  }, [])

  /*   useEffect(() => {
    if (IdUsuario > 0) {
      ClientesServices.getUsuariosAsignados(IdUsuario)
        .then((response) => {
          setClientes(response.data)
        })
        .catch((e: Error) => {  })

       PerfilesMenuServices.getAllTransportitas(IdUsuario)
        .then((response) => {
          setTransportistas(response.data)
        })
        .catch((e: Error) => {   }) 

      PerfilesMenuServices.getAllProveedores(IdUsuario)
        .then((response) => {
          setProveedores(response.data)
        })
        .catch((e: Error) => {   })
    }
  }, [IdUsuario]) */

  /*   const handleSelect = (e: any) => {
    setPerfiles(e)
  } */

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return mm + '/' + dd + '/' + yyyy
  }

  /*   const validateForm = (values: IRegister) => {
    const errors = { usuario: '', nombre: '', contrasena: '', correo: '' }
    if (!values.usuario) {
      errors.usuario = 'Campo Usuario es requerido'
    } else if (values.usuario.length > 50) {
      errors.usuario = 'Debe contener 50 caracteres o menos'
    }
    if (!values.nombre) {
      errors.nombre = 'Campo Nombre es requerido'
    } else if (values.nombre.length > 100) {
      errors.nombre = 'Debe contener 100 caracteres o menos'
    }
    if (!values.correo) {
      errors.correo = 'Campo correo es requerido'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.correo)
    ) {
      errors.correo = 'Correo invalido'
    }
    return errors
  } */

  function toggleShowPerfil() {
    setToastPerfil(!toastPerfil)
  }

  function construyePerfil(values: IPerfil) {
    if (UsuariosPerfilesMenu) {
      console.log(UsuariosPerfilesMenu)
      var opcionesPerfil = UsuariosPerfilesMenu.filter(function (el) {
        return el.idPerfil == values.id
      })
      setPerfilActual(opcionesPerfil)
    }
  }

  async function addCliente(id: number, e: any) {
    const asignacionUsuarioCliente: DTOUsuarioCliente = {
      sClave: id,
      IdUsuario: IdUsuario,
      agregar: e.currentTarget.checked,
    }
    ClientesServices.create(asignacionUsuarioCliente)
      .then((response) => {
        loadProveedores()
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }

  async function asignaClienteProveedor(
    sClaveCliente: string,
    sClave: string,
    e: any
  ) {
    const data: DTOasignaClienteProveedor = {
      IdUsuario: IdUsuario,
      sClaveCliente: sClaveCliente,
      sClave: sClave,
      asignado: e.currentTarget.checked,
    }
    console.log('data= ' + JSON.stringify(data))
    ClientesServices.asignaClienteProveedor(data)
      .then((response) => {
        console.log('Asignacion ' + JSON.stringify(response))
      })
      .catch((e: Error) => {})
  }

  async function asignaClienteTransportista(sClave: string, e: any) {
    const data: DTOClienteTransportista = {
      IdUsuario: IdUsuario,
      sClave: sClave,
      asignado: e.currentTarget.checked,
    }
    ClientesServices.asignaClienteTransportista(data)
      .then((response) => {
        console.log('Asignacion ' + JSON.stringify(response))
      })
      .catch((e: Error) => {})
  }

  function loadProveedores() {
    console.log('Entro a cargar proveedores ')
    PerfilesMenuServices.getAllProveedores(1)
      .then((response) => {
        setProveedores(response.data)
      })
      .catch((e: Error) => {})
  }

  function createUser(data: IRegister) {
    usuariosServices
      .create(data)
      .then((response) => {
        // console.log(JSON.stringify(response));
        if (response.status === 200) {
          setmsgDialog('El usuario ha sido guardado existosamente')
          setShowDialog(true)
        }
      })
      .catch((e: Error) => {
        setmsgDialog('Ocurrio un error: ' + e)
        setShowDialog(true)
      })
  }

  const CloseModal = (show: boolean): void => {
    setModalUserList(false)
  }

  const CloseModalAP = (show: boolean): void => {
    setmodalAdmonPerfiles(false)
  }

  const CloseModalPerfiles = (show: boolean): void => {
    //setmodalAdmonPerfiles(false);
  }

  //function loadAllUserInfo(id: number) {}

  const GetUserInfo = (data: IRegister): void => {
    setIdUsuario(data.id)
    setUsuario(data.usuario)
    setNombre(data.nombre)
    setContrasena(data.contrasena)
    setCorreo(data.correo)
    setIdPerfil(data.idPerfil)
    setTipoUsuario(data.tipoUsuario)
    console.log('info usuario=' + JSON.stringify(data))
    //loadAllUserInfo(data.id)
  }

  const clonaInformacion = (): void => {
    if (IdPerfil == 0) {
      setToastPerfil(true)
      setmsgDialog('')
      return
    } else setShowConfirmation(true)
  }

  const ChangeUser2Clone = (e: any): void => {
    setUser2Clone(e.target.value)
    const usr = comboUsuarios.filter((item) => item.id == e.target.value)
    setUsuarioDestino(usr[0].usuario)
  }

  const handleClose = () => setShowConfirmation(false)

  const procedeClonacion = () => {
    const data: DTOClonarUsuario = {
      idUsuarioOrigen: IdUsuario,
      idUsuarioDestino: user2Clone,
    }
    usuariosServices
      .clonarUsuario(data)
      .then((response) => {
        if (response.status == 200) {
          setToastPerfil(true)
          setmsgDialog('Usuario clonado existosamente')
        }
      })
      .catch((e: Error) => {
        console.log(e)
      })
    setShowConfirmation(false)
  }

  const onChangePerfil = (e: any): void => {
    setIdPerfil(e.currentTarget.value)
  }

  return (
    <div className='container' style={{ paddingTop: '10px' }}>
      <div className='row'>
        <div className='col'>
          <Card>
            <Card.Body>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={(values, actions) => {
                  console.log(values, actions)
                  createUser(values)
                }}
                //  validate={validateForm}
              >
                {(formik) => {
                  const {
                    errors,
                    touched,
                    isValid,
                    dirty,
                    values,
                    handleSubmit,
                  } = formik
                  return (
                    <Form
                      id='login-form'
                      className='form'
                      onSubmit={handleSubmit}
                    >
                      <Accordion defaultActiveKey='0'>
                        <Accordion.Item eventKey='0'>
                          <Accordion.Header>
                            Informacion obligatoria
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className='row'>
                              <div className='col'>
                                <Button
                                  variant='primary'
                                  onClick={() => setModalUserList(true)}
                                >
                                  Ver usuarios
                                </Button>
                                <UserList
                                  show={modalUserList}
                                  CloseModal={CloseModal}
                                  GetUserInfo={GetUserInfo}
                                />
                              </div>
                              <div className='col'>
                                <Button
                                  variant='btn btn-secondary'
                                  onClick={() => setmodalAdmonPerfiles(true)}
                                >
                                  Admon perfiles
                                </Button>
                                <div>
                                  <AdmonPerfiles
                                    show={modalAdmonPerfiles}
                                    CloseModal={CloseModalAP}
                                    CloseModalPerfiles={CloseModalPerfiles}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <div className='form-group'>
                                  <div className='label'>ID</div>
                                  <Field
                                    id='ID'
                                    name='ID'
                                    className='form-control'
                                    placeholder=''
                                    value={formik.values.id}
                                    disabled='disabled'
                                  />
                                </div>
                              </div>
                              <div className='col'>
                                <div className='form-group'>
                                  <label
                                    className='text-info'
                                    htmlFor='usuario'
                                  >
                                    Usuario
                                  </label>
                                  <Field
                                    id='usuario'
                                    name='usuario'
                                    className='form-control'
                                    placeholder='usuario'
                                  />
                                  {errors.usuario && touched.usuario ? (
                                    <div>
                                      {' '}
                                      <span style={{ color: '#FF3346' }}>
                                        {' '}
                                        * {errors.usuario}{' '}
                                      </span>{' '}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <div className='form-group'>
                                  <label className='text-info' htmlFor='Nombre'>
                                    Nombre
                                  </label>
                                  <Field
                                    id='nombre'
                                    name='nombre'
                                    className='form-control'
                                    placeholder='nombre'
                                  />
                                  {errors.nombre && touched.nombre ? (
                                    <div>
                                      {' '}
                                      <span style={{ color: '#FF3346' }}>
                                        {' '}
                                        * {errors.nombre}{' '}
                                      </span>{' '}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className='col'>
                                <div className='form-group'>
                                  <label
                                    className='text-info'
                                    htmlFor='password'
                                  >
                                    Contraseña
                                  </label>
                                  <Field
                                    id='contrasena'
                                    name='contrasena'
                                    className='form-control'
                                    placeholder='contraseña'
                                    type='password'
                                    disabled='diabled'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <div className='form-group'>
                                  <label className='text-info' htmlFor='correo'>
                                    Correo
                                  </label>
                                  <Field
                                    id='correo'
                                    name='correo'
                                    className='form-control'
                                    placeholder='correo'
                                  />
                                  {errors.correo && touched.correo ? (
                                    <span style={{ color: '#FF3346' }}>
                                      {' '}
                                      <div>* {errors.correo}</div>{' '}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className='col'>
                                <div className='form-group'>
                                  <label
                                    className='text-info'
                                    htmlFor='tipousuario'
                                  >
                                    Tipo usuario
                                  </label>
                                  <FormCtrl.Control
                                    as='select'
                                    id='tipousuario'
                                    name='tipousuario'
                                    value={formik.values.tipoUsuario}
                                    onChange={(e) =>
                                      setTipoUsuario(parseInt(e.target.value))
                                    }
                                    className='form-select form-select-sm'
                                  >
                                    <option value='1'>Administrador</option>
                                    <option value='2'>ZINC</option>
                                    <option value='3'>GEMCO</option>
                                    <option value='4'>BROKER</option>
                                    <option value='5'>BROKER EXTERNO</option>
                                  </FormCtrl.Control>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <div className='form-group'>
                                  <label className='text-info' htmlFor='activo'>
                                    Activo
                                  </label>
                                  <Field
                                    as='select'
                                    name='activo'
                                    className='form-control'
                                  >
                                    <option value='1'>Activo</option>
                                    <option value='0'>Inactivo</option>
                                  </Field>
                                </div>
                              </div>
                              <div className='col'>
                                {' '}
                                <div className='form-group'>
                                  <label
                                    className='text-info'
                                    htmlFor='fechaAlta'
                                  >
                                    Fecha alta
                                  </label>
                                  <Field
                                    id='fechaAlta'
                                    name='fechaAlta'
                                    readOnly='readOnly'
                                    className='form-control'
                                    placeholder='fecha alta'
                                    defaultValue={currentDate()}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <div className='form-group'>
                                  <label
                                    className='text-info'
                                    htmlFor='usuarioAlta'
                                  >
                                    Usuario alta
                                  </label>
                                  <Field
                                    as='select'
                                    name='usuarioAlta'
                                    className='form-control'
                                  >
                                    <option value={IdUsuario}>
                                      {getUsuario()}
                                    </option>
                                  </Field>
                                </div>
                              </div>
                              <div className='col'>
                                <div className='form-group'>
                                  <label
                                    className='text-info'
                                    htmlFor='fechaModifico'
                                  >
                                    Fecha modifico
                                  </label>
                                  <Field
                                    id='fechaModifico'
                                    name='fechaModifico'
                                    readOnly='readOnly'
                                    className='form-control'
                                    placeholder='Fecha modifico'
                                    defaultValue={currentDate()}
                                  />
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className='row'>
                        <br />
                      </div>
                      <div className='row'>
                        <div className='col'></div>
                        <div className='col'></div>
                        <div className='col'>
                          <button
                            type='submit'
                            className='btn btn-primary btn-lg btn-block'
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </Card.Body>
          </Card>
        </div>
        <div className='col'>
          <Card style={IdUsuario > 0 ? {} : { display: 'none' }}>
            <Card.Body>
              <div className='row'>
                <div className='col'>
                  <div className='row'>
                    <div className='form-group'>
                      <Alert variant='primary'>
                        <Alert.Heading>
                          Seleccione el perfil que utilizara el usuario
                        </Alert.Heading>
                      </Alert>
                      <br />
                      <div className='form-group col-md-12'>
                        <Formik
                          initialValues={initialValuesPerfiles}
                          onSubmit={(values: any, actions: any) => {
                            console.log({ values, actions })
                            construyePerfil(values)
                            actions.setSubmitting(false)
                          }}
                        >
                          <Form id='FrmPerfiles' className='form'>
                            <div className='form-group'>
                              <Field
                                as='select'
                                id='id'
                                name='id'
                                className='form-select form-select-sm'
                                placeholder='Perfil'
                                value={IdPerfil}
                                onChange={onChangePerfil}
                              >
                                {perfiles.map((p) => {
                                  return (
                                    <option value={p.id}>{p.perfil}</option>
                                  )
                                })}
                              </Field>
                            </div>
                            <br />
                            {/*                             <Button type="submit" variant="primary">
                              Preview
                            </Button>
                            <br /> */}
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
                {/*                 <div className="col">
                  <MenuUsuario menu={perfilActual} />
                </div> */}
              </div>
              <br />
              {/*               <div className="row">
                <div className="col">
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={() => {
                      clonaInformacion()
                    }}
                  >
                    Clonar informacion para:
                  </Button>
                </div>
                <div className="col">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={user2Clone}
                    onChange={ChangeUser2Clone}
                  >
                    <option value={0}>-Seleccione-</option>
                    {comboUsuarios.map((u) => {
                      return <option value={u.id}>{u.usuario}</option>
                    })}
                  </select>
                </div>
              </div> */}
              <ToastContainer position='middle-center'>
                <Toast show={toastPerfil} onClose={toggleShowPerfil}>
                  <Toast.Header>
                    <strong className='me-auto'>Error!</strong>
                  </Toast.Header>
                  <Toast.Body>
                    Seleccione un perfil, para poder continuar...
                  </Toast.Body>
                </Toast>
              </ToastContainer>
            </Card.Body>
          </Card>
        </div>
      </div>
      <ToastContainer position={'middle-center'}>
        <Toast
          onClose={() => setShowDialog(false)}
          show={showDialog}
          delay={3000}
          bg='dark'
          autohide
        >
          <Toast.Header>
            <strong className='me-auto'>Informacion</strong>
          </Toast.Header>
          <Toast.Body style={{ backgroundColor: '#F8F9F9' }}>
            {msgDialog}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal show={showConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Favor de confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Esta seguro de que desea clonar la informacion de : <br />[ {Usuario}{' '}
          ] para ... [ {usuarioDestino} ] ?
        </Modal.Body>
        <Modal.Footer>
          <div className='row'>
            <div className='col'>
              <Button variant='secondary' onClick={handleClose}>
                No
              </Button>
            </div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'></div>
            <div className='col'>
              <Button variant='primary' onClick={procedeClonacion}>
                Si
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  logued,
  setProfile,
} from '../../store/features/userStatusSlice/userStatusSlice'
import ILogin from '../../Interfaces/Ilogin'
import ItemMenu from '../../Interfaces/Catalogos/IItemMenu'
import AuthDS from '../../Services/Auth/Auth.Service'
import '../../css/login.css'
import jwt_decode from 'jwt-decode'
import IjwtStructure from '../../Interfaces/IjwtStructure'
import { RootState } from '../../store/store'
import { AxiosError } from 'axios'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import Dashboard from '../Dashboard/Dashboard'
import { Button, Card, Col, Form, Nav, Row } from 'react-bootstrap'
import usuariosServices from '../../Services/Catalogos/Usuarios.Services'
import FService from '../Cliente/Services/Facturas.Services'
import { populateCatTransfers } from '../../store/features/Clientes/1868/CatTransfersSlice'
import { useEffect, useState } from 'react'
import Info from '../../../package.json'

export interface ILoginProps {}

export default function Login(props: ILoginProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let _menu: ItemMenu[] = []
  const [mainMenu, setMainMenu] = useState(_menu)
  const userLogued = useSelector((state: RootState) => state.userStatus.value)
  const [show, setShow] = React.useState(false)
  const [Usuario, setUsuario] = React.useState('')
  const [Contrasenia, setContrasenia] = React.useState('')
  const [msg, setMsg] = React.useState('')
  const [header, setHeader] = React.useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const menu = localStorage.getItem('menu') ? localStorage.getItem('menu') : ''

  const login = () => {
    const credentials: ILogin = {
      Usuario: Usuario,
      Contrasena: Contrasenia,
    }
    AuthDS.login(credentials)
      .then((response: any) => {
        localStorage.setItem('menu', JSON.stringify(response.data.menu))
        localStorage.setItem('token', response.data.token)
        localStorage.setItem(
          'tokenInfo',
          JSON.stringify(jwt_decode(response.data.token))
        )
        var values: IjwtStructure = jwt_decode(response.data.token)
        localStorage.setItem('UserId', JSON.stringify(values.UserId))
        localStorage.setItem('User', JSON.stringify(values.Usuario))
        localStorage.setItem(
          'Departamento',
          JSON.stringify(values.Departamento)
        )
        localStorage.setItem('UserType', JSON.stringify(values.UserType))
        localStorage.setItem('Perfil', JSON.stringify(values.Perfil))
        dispatch(logued(true))
        dispatch(setProfile(JSON.stringify(values.Perfil).replace(/"/gi, '')))
        loadTransferData()
      })
      .catch((e: AxiosError) => {
        AuthDS.loginTransaport(credentials)
          .then((response: any) => {
            localStorage.setItem('menu', JSON.stringify(response.data.menu))
            localStorage.setItem('token', response.data.token)
            localStorage.setItem(
              'tokenInfo',
              JSON.stringify(jwt_decode(response.data.token))
            )
            var values: IjwtStructure = jwt_decode(response.data.token)
            localStorage.setItem('UserId', JSON.stringify(values.UserId))
            localStorage.setItem('User', JSON.stringify(values.Usuario))
            localStorage.setItem(
              'Departamento',
              JSON.stringify(values.Departamento)
            )
            localStorage.setItem('UserType', JSON.stringify(values.UserType))
            localStorage.setItem('Perfil', JSON.stringify(values.Perfil))
            dispatch(logued(true))
            dispatch(
              setProfile(JSON.stringify(values.Perfil).replace(/"/gi, ''))
            )
            loadTransferData()
            window.location.reload()
          })
          .catch((e: AxiosError) => {
            localStorage.setItem('menu', '')
            localStorage.setItem('token', '')
            setShow(true)
            setMsg('Credenciales invalidas!')
            setHeader('Error')
            if (e.message.includes('400')) {
              setMsg('Credenciales invalidas!')
            } else if (e.message.includes('401')) {
              setMsg(
                'Debere cambiar la contraseña, para poder entrar a este sitio'
              )
            }
          })
      })
  }

  useEffect(() => {
    if (menu && userLogued) {
      setMainMenu(JSON.parse(menu))
    } else {
      setMainMenu([])
    }
  }, [menu, userLogued])

  useEffect(() => {
    usuariosServices
      .validate()
      .then((response) => {
        if (response.status === 200) {
          dispatch(logued(true))
        }
      })
      .catch((e: Error) => {
        if (!window.location.href.indexOf('login')) {
          localStorage.setItem('menu', '')
          localStorage.setItem('token', '')
          setHeader('Informativo')
          setMsg('Su session ha expirado, necesita volver a loguerse')
          setShow(true)
          navigate('/')
        }
      })
  }, [dispatch, navigate])

  const loadTransferData = () => {
    FService.getCatTransfers()
      .then((response) => {
        dispatch(populateCatTransfers(response.data))
        return
      })
      .catch((e: Error) => {
        return
      })
  }

  return (
    <div>
      {!userLogued ? (
        <div>
          <Row style={{ paddingTop: '6rem' }}>
            <Col xs={12}></Col>
          </Row>
          <Row>
            <Col xs={5}>&nbsp;</Col>
            <Col xs={2}>
              <div className='shadow-lg bg-white rounded'>
                <Card style={{ width: '100%' }} className='Color1'>
                  <Card.Header>
                    <Row style={{ backgroundColor: '#002f87' }}>
                      <Col xs={12}>
                        <img
                          src='https://zinc.gemcousa.solutions/images/grupo-promax-b.png'
                          alt='ZINC Internacional'
                          style={{ width: '70%' }}
                        ></img>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Row>
                        <Col xs={12}>&nbsp;</Col>
                      </Row>
                      <Form id='login-form' className='form'>
                        <Row>
                          <Col>
                            <Form.Control
                              id='Usuario'
                              className='form-control'
                              placeholder='Usuario'
                              value={Usuario}
                              onChange={(e) => {
                                setUsuario(e.target.value)
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>&nbsp;</Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Form.Control
                              id='Contrasena'
                              className='form-control'
                              placeholder='Contraseña'
                              type='password'
                              value={Contrasenia}
                              onChange={(e) => {
                                setContrasenia(e.target.value)
                              }}
                              onKeyDown={(e) => e.key === 'Enter' && login()}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Card.Text>
                    <div className='d-grid gap-1'></div>
                    <div className='d-grid gap-1'>
                      <Button
                        variant='customColor'
                        size='lg'
                        onClick={() => {
                          login()
                        }}
                      >
                        login
                      </Button>
                    </div>
                    <Nav.Link
                      style={{ color: '#002f87' }}
                      href='/#/forgotPassword'
                    >
                      ¿Olvido contraseña?
                    </Nav.Link>
                    <div style={{ textAlign: 'right' }}>v{Info.version}</div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col xs={5}>&nbsp;</Col>
          </Row>
          <MsgInformativo
            show={show}
            msg={msg}
            header={header}
            msgColor={msgColor}
            closeToast={function (arg: boolean): void {
              setShow(false)
            }}
          />
        </div>
      ) : (
        <div>
          <Dashboard />
        </div>
      )}
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatTransfers from '../../../../Components/Cliente/Interfaces/ICatTransfers'
const CatTransfers: ICatTransfers[] = []
const initialState = { CatTransfers }

export const CatTransfersSlice = createSlice({
  name: 'CatTransfers',
  initialState: initialState,
  reducers: {
    populateCatTransfers: (state, action: PayloadAction<ICatTransfers[]>) => {
      action.payload.forEach((newItem) => {
        var Existe = state.CatTransfers.find(function (onMemoryItem) {
          return onMemoryItem.id === newItem.id
        })
        if (!Existe) state.CatTransfers.push(newItem)
      })
    },
    addCatTransfers: (state, action: PayloadAction<ICatTransfers>) => {
      var Existe = state.CatTransfers.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatTransfers.push(action.payload)
    },
    updateCatTransfers: (state, action: PayloadAction<ICatTransfers>) => {
      const i = state.CatTransfers.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatTransfers[i] = action.payload
      else state.CatTransfers.push(action.payload)
    },
    deleteCatTransfers: (state, action: PayloadAction<number>) => {
      const newArr = state.CatTransfers.filter(
        (data) => data.id != action.payload
      )
      state.CatTransfers = newArr
    },
  },
})

export const {
  addCatTransfers,
  populateCatTransfers,
  updateCatTransfers,
  deleteCatTransfers,
} = CatTransfersSlice.actions
export default CatTransfersSlice.reducer

import * as React from 'react'
import { Card, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FcAlarmClock, FcClock } from 'react-icons/fc'
import { BsBarChart, BsBarChartFill, BsBarChartLineFill } from 'react-icons/bs'
import { IconContext } from 'react-icons'

export interface IDashboardClienteProps {}

export default function DashboardCliente(props: IDashboardClienteProps) {
  return (
    <>
      <Col>
        <Card
          style={{ width: '18rem', fontFamily: 'Verdana' }}
          className='dashboardComponentFont'
        >
          <Card.Header>CRUCES</Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>Tiempos</Card.Title>
            {/* <Card.Img variant='top' src='' onClick={() => {}} /> */}
            <div style={{ fontSize: '6em' }} className='text-center'>
              <IconContext.Provider value={{ color: '#72F056' }}>
                <BsBarChartFill />
              </IconContext.Provider>
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../KPICruces'
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px',
                fontFamily: 'Verdana',
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card
          style={{ width: '18rem', fontFamily: 'Verdana' }}
          className='dashboardComponentFont'
        >
          <Card.Header>CRUCES</Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>Tiempo promedio</Card.Title>
            {/* <Card.Img variant='top' src='' onClick={() => {}} /> */}
            <div style={{ fontSize: '6em' }} className='text-center'>
              <FcAlarmClock />
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../KPICrucesTotales'
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px',
                fontFamily: 'Verdana',
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card
          style={{ width: '18rem', fontFamily: 'Verdana' }}
          className='dashboardComponentFont'
        >
          <Card.Header>Agente Aduanal</Card.Header>
          <Card.Body style={{ paddingBottom: '50px' }}>
            <Card.Title>Tiempos</Card.Title>
            {/* <Card.Img variant='top' src='' onClick={() => {}} /> */}
            <div style={{ fontSize: '6em' }} className='text-center'>
              <FcClock />
            </div>
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../KPIAgenteAduanal'
              style={{
                textDecoration: 'none',
                float: 'right',
                paddingRight: '10px',
              }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
    </>
  )
}

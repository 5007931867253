import http from '../../../Services/common/http-common'
import ICatBroker from '../Interfaces/ICatBroker'
import ICatBrokersCorreo from '../Interfaces/ICatBrokersCorreo'

class CatBrokersDataServices {
  Get() {
    return http.get<ICatBroker[]>(`/CatBrokers/Get`)
  }
  CorreosGet() {
    return http.get<ICatBrokersCorreo[]>(`/CatBrokers/Correos/Get`)
  }
  CorreosAppend(data: ICatBrokersCorreo) {
    return http.post<ICatBroker>(`/CatBrokers/Correos/Append`, data)
  }
  CorreoDelete(id: number) {
    return http.delete<Boolean>(`/CatBrokers/Correos/Delete/${id}`)
  }
}
export default new CatBrokersDataServices()

import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { BsEraserFill, BsFillPencilFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import FService from '../Services/CatNotificaciones.Services'
import { RootState } from '../../../store/store'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import ICatNotificaciones from '../Interfaces/ICatNotificaciones'
import {
  populateCatNotificaciones,
  updateCatNotificaciones,
} from '../../../store/features/Clientes/1868/CatNotificaciones'

export interface ICatNotificacionesProps {}

export default function CatNotificaciones(props: ICatNotificacionesProps) {
  const Perfil = () => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }
  const dispatch = useDispatch()
  const mCatNotificaciones = useSelector(
    (state: RootState) => state.CatNotificaciones.CatNotificaciones
  )
  const mProfile = useSelector(
    (state: RootState) => state.userStatus.Profile
  )
  const [ID, setID] = useState(0)
  const [Mensaje, setMensaje] = useState('')
  const [Direccion, setDireccion] = useState('')
  const [Buscar, setBuscar] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [Activo, setActivo] = useState(false)

  const colData = [
    {
      name: 'id',
      width: '5%',
      selector: (row: ICatNotificaciones) => row.id,
      sortable: true,
    },
    {
      name: 'Mensaje',
      width: '80%',
      selector: (row: ICatNotificaciones) => row.mensaje,
    },
    {
      name: 'Edita',
      cell: (row: ICatNotificaciones) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer', visibility: (mProfile==='Administrador') ? 'visible' : 'hidden' }}
          onClick={() => {
            setID(row.id)
            setMensaje(row.mensaje)
            setActivo(row.activo)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Activo',
      cell: (row: ICatNotificaciones) => (
        <div style={{ textAlign: 'center', cursor: 'pointer' }}>
          <Form.Check
            type='switch'
            id='custom-switch'
            label=''
            checked={row.activo}
            onClick={() => {
                save(row.id, row.mensaje, !row.activo)
            }}
            style={{}}
          />
        </div>
      ),
    },
  ]

  const save = (mID: number, mMensaje: string, mActivo: boolean) => {
    console.log(mID + ' ' +mMensaje+' '+mActivo)
    const data: ICatNotificaciones = {
      id: mID,
      mensaje: mMensaje ? mMensaje : '',
      activo: mActivo
    }
    FService.Append(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(updateCatNotificaciones(response.data))
        setID(response.data.id)
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatNotificaciones = () => {
    FService.Get()
      .then((response) => {
        dispatch(populateCatNotificaciones(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadCatNotificaciones()
  }, [])

  const cleanForm = () => {
    setID(0)
    setMensaje('')
    setDireccion('')
  }

  return (
    <div>
      <Row style={{ paddingTop: '10px', visibility: (mProfile==='Administrador') ? 'visible' : 'hidden' }}>
        <Col></Col>
        <Col xs={10}>
          <Card style={{}}>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={1}>
                    <Button
                      variant='warning'
                      size='sm'
                      onClick={() => {
                        cleanForm()
                      }}
                    >
                      <BsEraserFill />
                    </Button>{' '}
                    &nbsp; Mensaje
                  </Col>
                  <Col xs={10}>
                    <Form.Control
                      type='text'
                      id='Mensaje'
                      value={Mensaje}
                      size='sm'
                      onChange={(e) => {
                        setMensaje(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={1} style={{ textAlign: 'right' }}>
                    <Button variant='primary' onClick={() => save(ID, Mensaje, Activo)} size='sm'>
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col></Col>
      </Row>

      <Row style={{ paddingTop: '10px' }}>
        <Col></Col>
        <Col xs={10}>
          <Card style={{}}>
            <Card.Body>
              <Card.Text>
                <Row style={{ paddingTop: '5px' }}>
                  <Col xs={12}>
                    <Form.Control
                      type='text'
                      id='Buscar'
                      value={Buscar}
                      placeholder='Search...'
                      size='sm'
                      onChange={(e) => {
                        setBuscar(e.target.value)
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <DataTable
                      noHeader
                      defaultSortFieldId={'id'}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      columns={colData}
                      data={mCatNotificaciones.filter(function (row) {
                        return row.mensaje
                          .toLowerCase()
                          .includes(Buscar.toLowerCase())
                      })}
                    />
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col></Col>
      </Row>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import http from '../../../Services/common/http-common'
import DTO1868Clientes from '../DTO/DTO1868Clientes'
import DTO1868Factura from '../DTO/DTO1868Factura'
import DTO1868FacturaDetalle from '../DTO/DTO1868FacturaDetalle'
import DTO1868ReporteFactura from '../DTO/DTO1868ReporteFactura'
import DTO1868Transportista from '../DTO/DTO1868Transportista'
import DTO1868SCACCAAT from '../DTO/DTO1868SCACCAAT'
import DTORespuesta from '../DTO/DTORespuesta'
import I1868CatClaveSAT from '../Interfaces/I1868CatClaveSAT'
import I1868CatDestinos from '../Interfaces/I1868CatDestinos'
import I1868CatReponsables from '../Interfaces/I1868CatResponsables'
import I1868Factura from '../Interfaces/I1868Factura'
import I1868FacturaDetalle from '../Interfaces/I1868FacturaDetalle'
import DTOFechaArribo from '../DTO/DTOFechaArribo'
import ICatTransfers from '../Interfaces/ICatTransfers'
import IComentariosLog from '../Interfaces/ICatComentariosLog'

class C1868FacturasDataService {
  getAll(
    Inicio: string,
    Fin: string,
    UserId: number,
    Perfil: string,
    Status: number
  ) {
    return http.get<DTO1868ReporteFactura[]>(
      `/Clientes/1868/C1868Factura/getAllInvoices?Inicio=${Inicio}&Fin=${Fin}&UserId=${UserId}&Perfil=${Perfil}&Status=${Status}`
    )
  }
  getAllDetail(Inicio: string, Fin: string) {
    return http.get<I1868FacturaDetalle[]>(
      `/Clientes/1868/C1868Factura/getAllDetail?Inicio=${Inicio}&Fin=${Fin}`
    )
  }
  getClientes() {
    return http.get<DTO1868Clientes[]>(
      `/Clientes/1868/C1868Factura/getClientes`
    )
  }
  getCatClaveSAT() {
    return http.get<I1868CatClaveSAT[]>(
      `/Clientes/1868/C1868Factura/getCatClaveSAT`
    )
  }
  getCatResponsables() {
    return http.get<I1868CatReponsables[]>(
      `/Clientes/1868/C1868Factura/getCatResponsables`
    )
  }
  appendCatResponsables(data: I1868CatReponsables) {
    return http.post<I1868CatReponsables>(
      `/Clientes/1868/C1868Factura/appendCatResponsables`,
      data
    )
  }
  getRptTransportista(
    Inicio: string,
    Fin: string,
    UserId: number,
    Perfil: string,
    Status: number
  ) {
    return http.get<DTO1868Transportista[]>(
      `/Clientes/1868/C1868Factura/getAllInvoices?Inicio=${Inicio}&Fin=${Fin}&UserId=${UserId}&Perfil=${Perfil}&Status=${Status}`
    )
  }
  Append(id: number, Proceso: number) {
    return http.post<I1868Factura[]>(
      `/Clientes/1868/C1868Factura/UploadFile?id=${id}&Proceso=${Proceso}`
    )
  }
  RelateByProcess(id: number, idFile: number, Proceso: number) {
    return http.post<I1868Factura[]>(
      `/Clientes/1868/C1868Factura/RelateByProcess?id=${id}&idFile=${idFile}&Proceso=${Proceso}`
    )
  }
  UnRelateFileByProcess(IDFactura: number, Proceso: number) {
    return http.post<Boolean>(
      `/Clientes/1868/C1868Factura/UnRelateFileByProcess?IDFactura=${IDFactura}&Proceso=${Proceso}`
    )
  }
  Update(data: DTO1868Factura) {
    return http.put<DTORespuesta>(
      `/Clientes/1868/C1868Factura/UpdateMaster/${data.id}`,
      data
    )
  }
  UpdateDetail(data: DTO1868FacturaDetalle) {
    return http.put<I1868FacturaDetalle>(
      `/Clientes/1868/C1868Factura/UpdateDetail/${data.id}`,
      data
    )
  }
  getFileContent(id: number, Proceso: number) {
    return http
      .get<ArrayBuffer>(`/Clientes/1868/C1868Factura/getFileContent?id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  AppendSCACCAAT(data: DTO1868SCACCAAT) {
    return http.put<DTO1868SCACCAAT>(
      `/Clientes/1868/C1868Factura/AppendSCACCAAT/${data.id}`,
      data
    )
  }
  DeleteInvoice(id: number) {
    return http.delete<Boolean>(
      `/Clientes/1868/C1868Factura/DeleteInvoice/${id}`
    )
  }
  UpdateFechaArribo(data: DTOFechaArribo) {
    return http.put<Boolean>(
      `/Clientes/1868/C1868Factura/UpdateFechaArribo/${data.id}`,
      data
    )
  }
  UpdateVigenciaDODA(IdDODA: number, VigenciaDODA: string) {
    return http.put<Boolean>(
      `/Clientes/1868/C1868Factura/UpdateVigenciaDODA/${IdDODA}`,
      VigenciaDODA
    )
  }
  UpdateReferenciaRemesa(
    IdFactura: number,
    Referencia: string,
    Remesa: string,
    PedimentoLargo: string
  ) {
    return http.post<Boolean>(
      `/Clientes/1868/C1868Factura/UpdateReferenciaRemesa?IdFactura=${IdFactura}&Referencia=${Referencia}&Remesa=${Remesa}&PedimentoLargo=${PedimentoLargo}`
    )
  }
  getCatTransfers() {
    return http.get<ICatTransfers[]>(
      `/Clientes/1868/C1868Factura/Catalogos/Transfers/GetAll`
    )
  }
  appendCatTransfers(data: ICatTransfers) {
    return http.post<ICatTransfers>(
      `/Clientes/1868/C1868Factura/Catalogos/Transfers/Append`,
      data
    )
  }
  getBL2PDF(id: number) {
    return http
      .get<ArrayBuffer>(`{{process.env.REACT_SERVICES_API}}?id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  EstatusViajeUpdate(id: number, Estatus: number) {
    return http.put<Boolean>(
      `/Clientes/1868/C1868Factura/EstatusViajeUpdate/${id}/${Estatus}`
    )
  }
  RechazaViaje(id: number, Estatus: number, Comentario: string) {
    return http.put<Boolean>(
      `/Clientes/1868/C1868Factura/RechazaViaje/${id}/${Estatus}/${Comentario}`
    )
  }
  ComentariosGet(id: number) {
    return http.post<IComentariosLog[]>(
      `/Clientes/1868/C1868Factura/Comentarios/Get?id=${id}`
    )
  }
  ComentarioAppend(id: number, Usuario: string, Comentario: string) {
    return http.post<Boolean>(
      `/Clientes/1868/C1868Factura/Comentarios/Append?id=${id}&Usuario=${Usuario}&Comentario=${Comentario}`
    )
  }
}
export default new C1868FacturasDataService()

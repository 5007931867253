import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

const initialState = {
  value: false,
  Profile: '',
}

export const userStatusSlice = createSlice({
  name: 'userStatus',
  initialState,
  reducers: {
    logued: (state, action: PayloadAction<boolean>) => {
     state.value = action.payload;
    },
    setProfile: (state, action: PayloadAction<string>) => {
      state.Profile = action.payload;
     },
  },
})

export const { logued, setProfile } = userStatusSlice.actions;
export const selectuserStatus = (state: RootState) => state.userStatus.value
export default userStatusSlice.reducer;

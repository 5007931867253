import { FC, useEffect, useState } from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import {
  BsChatRightText,
  BsChatText,
  BsCheckCircleFill,
  BsFileEarmarkExcel,
  BsFillPencilFill,
  BsQuestionDiamondFill,
  BsSearch,
  BsXCircleFill,
} from 'react-icons/bs'
import FService from '../../Services/Facturas.Services'
import xlsx from 'json-as-xlsx'
import DTO1868Transportista from '../../DTO/DTO1868Transportista'
import FileManager from '../../../Utils/FileManager/FileManager'
import FileManagerDS from '../../../../Services/Utils/FileManager.Services'
import { IconContext } from 'react-icons'
import DTO1868SCACCAAT from '../../DTO/DTO1868SCACCAAT'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import DTOFechaArribo from '../../DTO/DTOFechaArribo'
import InputMask from 'react-input-mask'
import { populateCatTransfers } from '../../../../store/features/Clientes/1868/CatTransfersSlice'
import axios from 'axios'

interface IProps {}

interface ILog {
  comentario: string
  evento: string
  id: number
  id_factura: number
  usuario: string
}

export default function RptTransportista(props: IProps) {
  const dispatch = useDispatch()
  const mCatTransfers = useSelector(
    (state: RootState) => state.CatTransfers.CatTransfers
  )
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Data, setData] = useState<DTO1868Transportista[]>([])
  const [Log, setLog] = useState<ILog[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTO1868Transportista[]>([])
  const [ID, setID] = useState(0)
  const [Process, setProcess] = useState(9)
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-7))
  const [Fin, setFin] = useState(currentDate(0))
  const [modalFileManagerPDF, setModalFileManagerPDF] = useState(false)
  const [ModalData, setModalData] = useState(false)
  const [filtro, setFiltro] = useState('')
  const [SCAC, setSCAC] = useState('')
  const [CAAT, setCAAT] = useState('')
  const [TipoTransportista, setTipoTransportista] = useState(0)
  const [IDTransportistaViaje, setIDTransportistaViaje] = useState(0)
  const [IDTransportistaCruce, setIDTransportistaCruce] = useState(0)
  const [IdDODA, setIdDODA] = useState(0)
  const [IdPrefile, setIdPrefile] = useState(0)
  const [msgColor, setMsgColor] = useState('primary')
  const [FechaCruce, setFechaCruce] = useState<Date>(new Date())
  const [FechaArribo, setFechaArribo] = useState<Date>(new Date())
  const [Status, setStatus] = useState(1)
  const [FolioCartaPorte, setFolioCartaPorte] = useState('')
  const [ModalComments, setModalComments] = useState(false)
  const [ModalTripStatus, setModalTripStatus] = useState(false)
  const [StatusViaje, setStatusViaje] = useState(1)
  const [Comentario, setComentario] = useState('')
  const [ModalRejectTrip, setModalRejectTrip] = useState(false)
  const colsData = [
    {
      name: 'Factura',
      center: true,
      width: '75px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idpdf, 8)
          }}
        >
          {row.idpdf > 0 ? (
            <IconContext.Provider value={{ color: 'green', size: '17px' }}>
              <BsCheckCircleFill />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsXCircleFill />
            </IconContext.Provider>
          )}
        </div>
      ),
    },
    {
      name: 'XML',
      center: true,
      width: '75px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showXML(row.idxml, row.folioReferencia + '.xml')
          }}
        >
          {row.idxml > 0 ? (
            <IconContext.Provider value={{ color: 'green', size: '17px' }}>
              <BsCheckCircleFill />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsXCircleFill />
            </IconContext.Provider>
          )}
        </div>
      ),
    },
    {
      name: 'DODA',
      center: true,
      width: '70px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idDoda, 23)
          }}
        >
          {row.idDoda > 0 ? (
            <IconContext.Provider value={{ color: 'green', size: '17px' }}>
              <BsCheckCircleFill />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsXCircleFill />
            </IconContext.Provider>
          )}
        </div>
      ),
    },
    {
      name: 'Prefile',
      center: true,
      width: '60px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            showPDF(row.idPrefile, 24)
          }}
        >
          {row.idPrefile > 0 ? (
            <IconContext.Provider value={{ color: 'green', size: '17px' }}>
              <BsCheckCircleFill />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsXCircleFill />
            </IconContext.Provider>
          )}
        </div>
      ),
    },
    {
      name: 'BL',
      center: true,
      width: '60px',
      cell: (row: DTO1868Transportista) =>
        row.referencia && row.pedimentoLargo && row.idbl === 0 ? (
          <div
            onClick={() => {
              showBL2PDF(row.id, row.referencia)
            }}
          >
            <IconContext.Provider value={{ color: 'green', size: '17px' }}>
              <BsCheckCircleFill />
            </IconContext.Provider>
          </div>
        ) : (
          <div
            style={{ textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {
              showPDF(row.idbl, 29)
            }}
          >
            {row.idbl > 0 ? (
              <IconContext.Provider value={{ color: 'green', size: '17px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            ) : (
              <IconContext.Provider value={{ color: 'red', size: '17px' }}>
                <BsXCircleFill />
              </IconContext.Provider>
            )}
          </div>
        ),
    },
    {
      name: 'VIG. DODA',
      width: '110px',
      selector: (row: DTO1868Transportista) =>
        row.vigenciaDODA ? row.vigenciaDODA.substring(0, 10) : '',
      sortable: true,
    },
    {
      name: 'SCAC',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.scac,
      sortable: true,
    },
    {
      name: 'CAAT',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.caat,
      sortable: true,
    },
    {
      name: '',
      width: '55px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            visibility:
              row.activo === 1 && row.idDoda === 0 && row.estatusViaje == 2
                ? 'visible'
                : 'hidden',
          }}
          onClick={() => {
            if (row.idTransportistaViaje === parseInt(UserId)) {
              setTipoTransportista(2)
            } else {
              setTipoTransportista(1)
            }
            setID(row.id)
            setSCAC(row.scac)
            setCAAT(row.caat)
            setFechaCruce(
              row.fechaCruce ? new Date(row.fechaCruce) : new Date()
            )
            setIdDODA(row.idDoda)
            setIdPrefile(row.idPrefile)
            setIDTransportistaViaje(row.idTransportistaViaje)
            setIDTransportistaCruce(row.idResponsableCruce)
            setFolioCartaPorte(row.folioCartaPorte)
            setModalData(true)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '17px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'FCP',
      center: true,
      width: '60px',
      cell: (row: DTO1868Transportista) => (
        <div>
          {row.folioCartaPorte ? (
            <OverlayTrigger
              trigger='click'
              placement='right'
              overlay={popover(row.folioCartaPorte, 'Folio carta porte')}
            >
              <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                <IconContext.Provider value={{ color: 'green', size: '17px' }}>
                  <BsCheckCircleFill />
                </IconContext.Provider>
              </div>
            </OverlayTrigger>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsXCircleFill />
            </IconContext.Provider>
          )}
        </div>
      ),
    },
    {
      name: 'PL',
      center: true,
      width: '60px',
      cell: (row: DTO1868Transportista) => (
        <div>
          {row.pedimentoLargo ? (
            <OverlayTrigger
              trigger='click'
              placement='right'
              overlay={popover(row.pedimentoLargo, 'Pedimento')}
            >
              <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                <IconContext.Provider value={{ color: 'green', size: '17px' }}>
                  <BsCheckCircleFill />
                </IconContext.Provider>
              </div>
            </OverlayTrigger>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsXCircleFill />
            </IconContext.Provider>
          )}
        </div>
      ),
    },
    {
      name: 'Estatus cruce',
      width: '130px',
      center: true,
      cell: (row: DTO1868Transportista) => (
        <div>
          {row.estatusViaje == 2 ? 'Listo ' : 'Pendiente '}
          <span
            onClick={() => {
              ChangeTripStatus(
                row.id,
                row.idTransportistaViaje,
                row.idResponsableCruce,
                row.estatusViaje
              )
            }}
            style={{
              visibility:
                row.idTransportistaViaje !== row.idResponsableCruce
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <IconContext.Provider value={{ color: '#fe912a', size: '20px' }}>
              <BsQuestionDiamondFill />
            </IconContext.Provider>
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: '',
      width: '55px',
      cell: (row: DTO1868Transportista) => (
        <div
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            visibility: 'visible',
          }}
          onClick={() => {
            setID(row.id)
            getComments(row.id)
            setModalComments(true)
          }}
        >
          <IconContext.Provider value={{ color: '#f18b1f', size: 'px' }}>
            <BsChatText />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Responsable cruce',
      width: '250px',
      selector: (row: DTO1868Transportista) => row.responsableCruce,
      sortable: true,
    },
    {
      name: 'Sello',
      width: '100px',
      selector: (row: DTO1868Transportista) => (row.sello ? row.sello : ''),
      sortable: true,
    },
    {
      name: 'Placas',
      width: '190px',
      selector: (row: DTO1868Transportista) => row.placas,
      sortable: true,
    },
    {
      name: 'No Caja',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.ruta,
      sortable: true,
    },
    {
      name: 'Fec arribo',
      width: '110px',
      selector: (row: DTO1868Transportista) =>
        row.fechaArribo ? row.fechaArribo.substring(0, 10) : '',
      sortable: true,
    },
    {
      name: 'Fec Cruce',
      width: '150px',
      selector: (row: DTO1868Transportista) =>
        row.fechaCruce ? row.fechaCruce.substring(0, 10) : '',
      sortable: true,
    },
    {
      name: 'Pallets',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.pallets,
      sortable: true,
    },
    {
      name: 'Peso Bruto',
      width: '150px',
      selector: (row: DTO1868Transportista) =>
        row.pesoBruto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      sortable: true,
    },
    {
      name: 'Carga',
      width: '100px',
      selector: (row: DTO1868Transportista) => row.carga,
      sortable: true,
    },
    {
      name: 'Tipo pedimento',
      width: '135px',
      selector: (row: DTO1868Transportista) => row.tipoPedimento,
      sortable: true,
    },
    {
      name: 'Entregar en',
      width: '850px',
      selector: (row: DTO1868Transportista) => row.entregar,
      sortable: true,
    },
    {
      name: 'Aduana',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.aduana,
      sortable: true,
    },
    {
      name: 'Producto/Materia prima',
      width: '350px',
      selector: (row: DTO1868Transportista) => row.productoMateriaPrima,
      sortable: true,
    },
    {
      name: 'Fraccion arancelaria',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.fraccionArancelaria,
      sortable: true,
    },
    {
      name: 'Clave SAT',
      width: '150px',
      selector: (row: DTO1868Transportista) => row.claveSAT1,
      sortable: true,
    },
    {
      name: 'Concepto Producto / MP',
      width: '250px',
      selector: (row: DTO1868Transportista) => row.conceptoProductoMP,
      sortable: true,
    },
    {
      name: 'Empaque (descripcion)',
      width: '550px',
      selector: (row: DTO1868Transportista) => row.empaqueDescripcion,
      sortable: true,
    },
    {
      name: 'Clave SAT',
      width: '120px',
      selector: (row: DTO1868Transportista) => row.claveSAT2,
      sortable: true,
    },
  ]

  const conditionalRowStyles = [
    {
      when: (row: DTO1868Transportista) => row.urgente == 1,
      style: {
        backgroundColor: '#F9FC87',
        color: '#000000',
        '&:hover': {
          cursor: 'wait',
        },
      },
    },
  ]

  const showBL2PDF = (id: number, Invoice: string) => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_SERVICES + 'zinc/services/BOL2PDF' + '?id=' + id,
      responseType: 'blob',
    }).then(function (response) {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    })
  }

  const getComments = (id: number) => {
    FService.ComentariosGet(id)
      .then((response) => {
        setLog(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error!!!... ')
        setShowMsg(true)
        return
      })
  }

  const saveComment = (comentario: string) => {
    FService.ComentarioAppend(ID, User, comentario)
      .then((response) => {
        getComments(ID)
        setComentario('')
        setHeader('Confirmacion')
        setMsg('Comentario agregado')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error!!!... ')
        setShowMsg(true)
        return
      })
  }

  const rejectTrip = (comentario: string) => {
    FService.RechazaViaje(ID, 1, comentario)
      .then((response) => {
        setModalRejectTrip(false)
        saveComment(comentario)
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error!!!... ')
        setShowMsg(true)
        return
      })
  }

  const showXML = (id: number, Invoice: string) => {
    FService.getFileContent(id, 7)
      .then((response: any) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${Invoice}.xml`)
          document.body.appendChild(link)
          link.click()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado XML')
        setShowMsg(true)
        return
      })
  }

  const popover = (msg: string, leyend: string) => (
    <Popover id='popover-basic'>
      <Popover.Header as='h3'>{leyend}</Popover.Header>
      <Popover.Body>{msg}</Popover.Body>
    </Popover>
  )

  const showPDF = (id: number, Proceso: number) => {
    FService.getFileContent(id, Proceso)
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A esta factura no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  function exportExcel(
    jsonData: DTO1868Transportista[],
    fileName: string
  ): void {
    let Heading = [
      [
        'SCAC',
        'CAAT',
        'Responsable cruce',
        'Sello',
        'Placas',
        'No Carga',
        'Fecha cruce',
        'Pallets',
        'Peso Bruto',
        'Carga',
        'Tipo pedimento',
        'Entregar en',
        'Aduana',
        'Producto / Materia prima',
        'Fraccion  arancelaria',
        'Clave SAT',
        'Concepto producto / MP',
        'Empaque (descripcion)',
        'Clave SAT',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        scac,
        caat,
        responsableCruce,
        sello,
        placas,
        ruta,
        fechaCruce,
        pallets,
        pesoBruto,
        carga,
        tipoPedimento,
        entregar,
        aduana,
        conceptoProductoMP,
        fraccionArancelaria,
        claveSAT1,
        conceptoEmbalaje,
        empaqueDescripcion,
        claveSAT2,
      }) => {
        return {
          scac,
          caat,
          responsableCruce,
          sello,
          placas,
          ruta,
          fcruce: { fechaCruce },
          pallets,
          pesoBruto,
          carga,
          tipoPedimento,
          entregar,
          aduana,
          conceptoProductoMP,
          fraccionArancelaria,
          claveSAT1,
          conceptoEmbalaje,
          empaqueDescripcion,
          claveSAT2,
        }
      }
    )
    let data = [
      {
        sheet: 'Facturas',
        columns: [
          { label: 'SCAC', value: 'scac' },
          { label: 'CAAT', value: 'caat' },
          { label: 'Responsable cruce', value: 'responsableCruce' },
          { label: 'Sello', value: 'sello' },
          { label: 'Placas', value: 'placas' },
          { label: 'No Caja', value: 'ruta' },
          {
            label: 'Fecha Cruce',
            value: (row: any) =>
              row.fcruce.fechaCruce
                ? row.fcruce.fechaCruce.substring(0, 10)
                : '',
          },
          { label: 'Pallets', value: 'pallets' },
          { label: 'Peso Bruto', value: 'pesoBruto' },
          { label: 'Carga', value: 'carga' },
          { label: 'Tipo pedimento', value: 'tipoPedimento' },
          { label: 'Entregar', value: 'entregar' },
          { label: 'Aduana', value: 'aduana' },
          { label: 'Producto / Materia prima', value: 'conceptoProductoMP' },
          { label: 'Fraccion arancelaria', value: 'fraccionArancelaria' },
          { label: 'Clave SAT', value: 'claveSAT1' },
          { label: 'Concepto embalaje', value: 'conceptoEmbalaje' },
          { label: 'Empaque descripcion', value: 'empaqueDescripcion' },
          { label: 'Clave SAT', value: 'claveSAT2' },
        ],
        content: dataOnly,
      },
    ]
    let settings = {
      fileName,
      extraLength: 3,
      writeOptions: {},
      RTL: false,
    }
    xlsx(data, settings)
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const ChangeTripStatus = (
    id: number,
    IdTransportistaFederal: number,
    IdTransfer: number,
    CurrentStatus: number
  ) => {
    console.log(
      id,
      IdTransportistaFederal,
      IdTransfer,
      CurrentStatus,
      parseInt(UserId),
      Perfil
    )
    if (
      ((Perfil === 'Transportista' &&
        parseInt(UserId) === IdTransportistaFederal) ||
        Perfil === 'Administrador') &&
      CurrentStatus == 1
    ) {
      setID(id)
      setStatusViaje(CurrentStatus)
      setModalTripStatus(true)
    } else if (
      ((Perfil === 'Transportista' && parseInt(UserId) === IdTransfer) ||
        Perfil === 'Administrador') &&
      CurrentStatus == 2
    ) {
      setID(id)
      setStatusViaje(CurrentStatus)
      setModalRejectTrip(true)
    }
    return false
  }

  const generaReporte = () => {
    FService.getRptTransportista(Inicio, Fin, UserId, Perfil, Status)
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    let value = e.target.value
    value = value.toUpperCase()
    setFiltro(value)
    if (value.length > 0) {
      setData(
        Data.filter(function (row: DTO1868Transportista) {
          return (
            row.responsableCruce.includes(value) ||
            row.pallets.toString().includes(value) ||
            row.pesoBruto.toString().includes(value) ||
            row.carga.includes(value) ||
            row.tipoPedimento.includes(value) ||
            row.aduana.includes(value) ||
            row.fraccionArancelaria.includes(value)
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  const loadTransferData = () => {
    FService.getCatTransfers()
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(populateCatTransfers(response.data))
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        return
      })
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas de Zinc Internacional')
  }

  const uploadPDF = (idFile: number, Process: number) => {
    FService.Append(idFile, Process)
      .then((response) => {
        if (!response.data) {
          setHeader('Error')
          setMsg('Ocurrio un error, no se guardo correctamente el archivo')
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const validateSCACCAAT = (): boolean => {
    let data = mCatTransfers.filter((row) => {
      if (row.scac === SCAC && row.caat === CAAT && row.activo) {
        return row
      }
    })
    if (!data[0]) return false
    else return true
  }

  const saveData = () => {
    if (FolioCartaPorte.length < 32) {
      setHeader('Error')
      setMsg('Proporcione el Folio Carta Porte')
      setShowMsg(true)
      return false
    }
    if ((SCAC === '' && CAAT != '') || (SCAC != '' && CAAT === '')) {
      setHeader('Error')
      setMsg(
        'Para poder continuar debe proporcionar tanto el SCAC como el CAAT'
      )
      setShowMsg(true)
      return false
    }
    if (!validateSCACCAAT() && SCAC != '' && CAAT != '') {
      setHeader('Error')
      setMsg('SCAC y CAAT no corresponden al mismo transportista...')
      setShowMsg(true)
      return false
    }
    if (TipoTransportista === 2) {
      if (!FechaArribo) {
        setHeader('Error')
        setMsg('Para guardar la informacion proporcion la fecha de arribo')
        setShowMsg(true)
        return false
      }
      const data: DTOFechaArribo = {
        id: ID,
        fechaArribo: FechaArribo,
      }
      FService.UpdateFechaArribo(data)
        .then((response) => {})
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setModalData(false)
          return
        })
    }
    if (SCAC == null || CAAT == null) {
      setHeader('Error')
      setMsg('Para guardar la informacion proporcion el SCAC y el CAAT')
      setShowMsg(true)
      return false
    }
    const data: DTO1868SCACCAAT = {
      id: ID,
      scac: SCAC,
      caat: CAAT,
      fechaCruce: FechaCruce,
      folioCartaPorte: FolioCartaPorte,
    }
    FService.AppendSCACCAAT(data)
      .then((response) => {
        generaReporte()
        setHeader('Informativo')
        setMsg('La informacion se guardo existosamente')
        setModalData(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setModalData(false)
        return
      })
  }

  const dynamicBlock = (bloque: number) => {
    const bloque1 = (
      <>
        <Row style={{ paddingTop: '10px' }}>
          <Col xs={1}></Col>
          <Col xs={7} style={{ textAlign: 'right' }}>
            Fecha de arribo a Nuevo Laredo
          </Col>
          <Col xs={3}>
            <DatePicker
              selected={FechaArribo}
              onChange={(date: any) => setFechaArribo(date)}
            />
          </Col>
          <Col xs={1}></Col>
        </Row>
        <hr />
      </>
    )
    const bloque2 = (
      <Row>
        <Col xs={1} style={{ textAlign: 'right' }}>
          SCAC
        </Col>
        <Col xs={2} style={{ textAlign: 'left' }}>
          <Form.Control
            type='text'
            id='SCAC'
            value={SCAC}
            size='sm'
            disabled={IdDODA > 0 && IdPrefile > 0}
            placeholder='SCAC'
            onChange={(e) => {
              setSCAC(e.target.value)
            }}
          />
        </Col>
        <Col xs={1} style={{ textAlign: 'right' }}>
          CAAT
        </Col>
        <Col xs={2} style={{ textAlign: 'left' }}>
          <Form.Control
            type='text'
            id='CAAT'
            value={CAAT}
            size='sm'
            disabled={IdDODA > 0 && IdPrefile > 0}
            placeholder='CAAT'
            onChange={(e) => {
              setCAAT(e.target.value)
            }}
          />
        </Col>
        <Col xs={2} style={{ textAlign: 'right' }}>
          Cruce
        </Col>
        <Col xs={3}>
          <DatePicker
            selected={FechaCruce}
            onChange={(date: any) => setFechaCruce(date)}
          />
        </Col>
        <Col xs={2}></Col>
      </Row>
    )
    if (bloque === 1) return bloque1
    else if (bloque === 2) return bloque2
    else
      return (
        <>
          {bloque1} {bloque2}
        </>
      )
  }

  const saveTripStatus = () => {
    FService.EstatusViajeUpdate(ID, StatusViaje)
      .then((response) => {
        setModalTripStatus(false)
        saveComment('Cambie el estatus de la caja a lista...')
        generaReporte()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error!!!... ')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadTransferData()
  }, [])

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </Col>
            <Col xs={1}></Col>
            <Col
              xs={1}
              style={{
                textAlign: 'right',
                fontFamily: 'Verdana',
                fontSize: '14px',
              }}
            >
              Viajes
            </Col>
            <Col xs={2} style={{ textAlign: 'left' }}>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setStatus(parseInt(e.target.value))
                }}
                className='form-select form-select-sm'
              >
                <option value='1'>Pendientes</option>
                <option value='2'>Terminados</option>
              </Form.Control>
            </Col>
            <Col xs={1}></Col>
            <Col xs={3}>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </Col>
            <Col className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </Col>
            <Col xs={1}>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Descarga
              </Button>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        {
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            striped={true}
            dense={true}
            paginationPerPage={18}
            pagination={true}
            highlightOnHover
            columns={colsData}
            data={Data}
            conditionalRowStyles={conditionalRowStyles}
            pointerOnHover
          />
        }
      </div>
      <Modal
        show={modalFileManagerPDF}
        onHide={() => setModalFileManagerPDF(false)}
        size='lg'
      >
        <Modal.Body>
          <FileManager
            width={750}
            height={200}
            IDProcess={Process}
            IdFile={ID}
            IDUser={UserId}
            FileName={''}
            canDelete={false}
            FileType={['pdf', 'PDF']}
            Leyenda={
              'Estimado colaborador, favor de seleccionar el archivo PDF, arrastrelo hasta aqui y sueltelo para agregar la informacion a este reporte...'
            }
            onAppendFM={function (idFile: number): void {
              uploadPDF(idFile, Process)
            }}
            onDeleteFM={function (IDMaster: number, Proceso: number): void {}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={11}>&nbsp;</Col>
            <Col xs={1}>
              <Button
                variant='secondary'
                onClick={() => setModalFileManagerPDF(false)}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal show={ModalData} onHide={() => setModalData(false)} size='lg'>
        <Row style={{ paddingTop: '20px' }}>
          <Col></Col>
          <Col xs={10}>
            {TipoTransportista >= 1 ? (
              <Alert variant='primary'>
                <Alert.Heading style={{ textAlign: 'center' }}>
                  Proporcione la siguiente informacion
                </Alert.Heading>
                {IDTransportistaCruce === IDTransportistaViaje
                  ? dynamicBlock(3)
                  : parseInt(UserId) === IDTransportistaViaje
                  ? dynamicBlock(1)
                  : dynamicBlock(2)}
                <hr />
                <Row>
                  <Col xs={1}></Col>
                  <Col xs={3}>
                    <Badge
                      bg='danger'
                      style={{
                        fontSize: '17px',
                        fontFamily: 'Arial',
                        paddingTop: '14px',
                      }}
                    >
                      Folio carta porte:
                    </Badge>
                  </Col>
                  <Col>
                    <FormControl
                      type='text'
                      as={InputMask}
                      mask='********-****-****-****-************'
                      value={FolioCartaPorte}
                      alwaysShowMask={true}
                      onChange={(e) => {
                        setFolioCartaPorte(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={1}></Col>
                </Row>
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col></Col>
        </Row>
        <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Col xs={2} style={{ textAlign: 'center' }}>
            <Button
              variant='secondary'
              onClick={() => setModalData(false)}
              size='sm'
            >
              Cerrar
            </Button>
          </Col>
          <Col xs={8}></Col>
          <Col xs={2} style={{ textAlign: 'center' }}>
            <Button variant='primary' onClick={() => saveData()} size='sm'>
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        show={ModalComments}
        onHide={() => setModalComments(false)}
        size='lg'
      >
        <Row
          style={{
            paddingTop: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <Col xs={12}>
            <Alert variant={'secondary'}>
              <div
                style={{
                  maxHeight: '150px',
                  overflowY: 'auto',
                  fontSize: '13px',
                  backgroundColor: 'white',
                }}
              >
                {Log
                  ? Log.map((item: ILog) => {
                      return (
                        <p style={{ paddingLeft: '5px' }}>
                          {item.evento}
                          <br />
                          <span style={{ fontWeight: 'bold' }}>
                            {item.usuario}
                          </span>{' '}
                          : <span>{item.comentario}</span>
                        </p>
                      )
                    })
                  : ''}
              </div>
            </Alert>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <Col xs={12}>
            <Alert variant={'dark'}>
              <FloatingLabel
                controlId='floatingTextarea'
                label='Proporcione sus comentarios'
              >
                <Form.Control
                  as='textarea'
                  placeholder='Proporcione sus comentarios'
                  style={{
                    height: '75px',
                    fontSize: '13px',
                  }}
                  value={Comentario}
                  onChange={(e) => {
                    setComentario(e.target.value)
                  }}
                />
              </FloatingLabel>
            </Alert>
          </Col>
        </Row>
        <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Col xs={2} style={{ textAlign: 'center' }}></Col>
          <Col xs={8}></Col>
          <Col xs={2} style={{ textAlign: 'center' }}>
            <Button
              variant='primary'
              onClick={() => saveComment(Comentario)}
              size='sm'
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        show={ModalTripStatus}
        onHide={() => setModalTripStatus(false)}
        size='lg'
      >
        <Row
          style={{
            paddingTop: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '15px',
            fontWeight: 'bold',
          }}
        >
          <Alert variant='primary'>
            Favor de confirmar cambio de estatus en el viaje
          </Alert>
        </Row>
        <Row
          style={{
            paddingTop: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '13px',
          }}
        >
          <Col xs={3}></Col>
          <Col xs={4} style={{ textAlign: 'right' }}>
            El estatus del viaje actualmente es:
          </Col>
          <Col xs={2}>
            <Form.Control
              as='select'
              onChange={(e) => {
                setStatusViaje(parseInt(e.target.value))
              }}
              value={StatusViaje}
              className='form-select form-select-sm'
            >
              <option value='2'>Listo</option>
              <option value='1'>Pendiente</option>
            </Form.Control>
          </Col>
        </Row>
        <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Col xs={2} style={{ textAlign: 'center' }}></Col>
          <Col xs={8}></Col>
          <Col xs={2} style={{ textAlign: 'center' }}>
            <Button
              variant='primary'
              onClick={() => saveTripStatus()}
              size='sm'
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        show={ModalRejectTrip}
        onHide={() => setModalRejectTrip(false)}
        size='lg'
      >
        <Row
          style={{
            paddingTop: '20px',
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '15px',
            fontWeight: 'bold',
          }}
        >
          <Alert variant='primary'>
            Si el remolque no esta listo, favor de indicar la razón:{' '}
          </Alert>
        </Row>
        <Row style={{ textAlign: 'right', padding: '5px' }}>
          <Col xs={12}>
            <FloatingLabel controlId='floatingTextarea2' label='Comentarios:'>
              <Form.Control
                as='textarea'
                placeholder='Comentarios:'
                style={{ height: '100px' }}
                value={Comentario}
                onChange={(e) => {
                  setComentario(e.target.value)
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Col xs={9}></Col>
          <Col xs={3} style={{ textAlign: 'center' }}>
            <Button
              variant='danger'
              onClick={() => rejectTrip(Comentario)}
              size='sm'
            >
              Guardar rechazo
            </Button>
          </Col>
        </Row>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import React, { FC, useEffect, useState } from 'react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from 'react-bootstrap'
import { RootState } from '../../../store/store'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { BsFillEraserFill, BsFillPencilFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillCloseCircle, AiFillSave, AiOutlineSave } from 'react-icons/ai'
import CatResponsablesDS from '../Services/CatResponsables.Services'
//import FService from '../Services/Facturas.Services'
import FService from '../Services/Facturas.Services'
import {
  addCatResponsables,
  populateCatResponsables,
  updateCatResponsables,
} from '../../../store/features/Clientes/1868/Cat1868ResponsablesSlice'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import I1868CatResponsablesCorreos from '../Interfaces/I1868CatResponsablesCorreos'
import I1868CatReponsables from '../Interfaces/I1868CatResponsables'
import ICatTransfers from '../Interfaces/ICatTransfers'
import {
  populateCatTransfers,
  updateCatTransfers,
} from '../../../store/features/Clientes/1868/CatTransfersSlice'

export interface ICatTransfersZProps {}

export default function CatTransfersZ(props: ICatTransfersZProps) {
  const dispatch = useDispatch()
  const mCatResponsables = useSelector(
    (state: RootState) => state.Cat1868Responsables.Cat1868Responsables
  )
  const mCatTransfers = useSelector(
    (state: RootState) => state.CatTransfers.CatTransfers
  )
  const [IDResponsable, setIDResponsable] = useState(0)
  const [Usuario, setUsuario] = useState('')
  const [Correo, setCorreo] = useState('')
  const [IDTransfer, setIDTransfer] = useState(0)
  const [IDNuevoResponsable, setIDNuevoResponsable] = useState(0)
  const [NuevoResponsable, setNuevoResponsable] = useState('')
  const [IDCorreo, setIDCorreo] = useState(0)
  const [DataCorreos, setDataCorreos] = useState<I1868CatResponsablesCorreos[]>(
    []
  )
  const [show, setShowMsg] = useState(false)
  const [ModalDeleteEmail, setModalDeleteEmail] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [SCAC, setSCAC] = useState('')
  const [CAAT, setCAAT] = useState('')

  const colData = [
    {
      name: 'id',
      width: '100px',
      selector: (row: I1868CatResponsablesCorreos) => row.id,
      sortable: true,
    },
    {
      name: 'Correo',
      selector: (row: I1868CatResponsablesCorreos) => row.correo,
    },
    {
      name: '',
      width: '80px',
      cell: (row: I1868CatResponsablesCorreos) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIDCorreo(row.id)
            setCorreo(row.correo)
          }}
        >
          <IconContext.Provider value={{ color: '#339dff', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: '',
      width: '80px',
      cell: (row: I1868CatResponsablesCorreos) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIDCorreo(row.id)
            setCorreo(row.correo)
            setModalDeleteEmail(true)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <AiFillCloseCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  useEffect(() => {
    loadCatTransfers()
    loadTransferData()
  }, [])

  useEffect(() => {}, [mCatTransfers])

  useEffect(() => {
    loadEmails(IDResponsable)
    if (IDResponsable !== 0) {
      const Usuario = mCatResponsables.filter(
        (user) => user.id === IDResponsable
      )
      setUsuario(Usuario[0] ? Usuario[0].usuario : '')
      //console.log('Usuario ' + Usuario[0] ? Usuario[0].usuario : '')
      const data = mCatTransfers.filter((tra) => tra.padre === IDResponsable)
      setSCAC(data[0] ? data[0].scac : '')
      setCAAT(data[0] ? data[0].caat : '')
      setIDTransfer(data[0] ? data[0].id : 0)
      setIDNuevoResponsable(0)
    } else {
      setSCAC('')
      setCAAT('')
    }
  }, [IDResponsable])

  useEffect(() => {
    if (IDResponsable > 0) {
      const data = mCatTransfers.filter((tra) => tra.padre === IDResponsable)
      setSCAC(data[0] ? data[0].scac : '')
      setCAAT(data[0] ? data[0].caat : '')
      setIDTransfer(data[0] ? data[0].id : 0)
      setIDNuevoResponsable(0)
    }
  }, [mCatTransfers])

  const deleteEmail = () => {
    CatResponsablesDS.DeleteEmail(IDCorreo)
      .then((response) => {
        setIDCorreo(0)
        setCorreo('')
        setModalDeleteEmail(false)
        loadEmails(IDResponsable)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatTransfers = () => {
    CatResponsablesDS.Get()
      .then((response) => {
        dispatch(
          populateCatResponsables(
            response.data.filter((t) => {
              if (t.padre == -1) {
                return t
              }
            })
          )
        )
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadEmails = (id: number) => {
    CatResponsablesDS.GetByResponsable(id)
      .then((response) => {
        setDataCorreos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveEmail = () => {
    if (IDResponsable === 0) {
      setHeader('Error')
      setMsg('Favor de seleccionar un responsable')
      setShowMsg(true)
      setIDCorreo(0)
      setCorreo('')
      return
    }
    if (Correo === '' && Usuario === '') {
      setHeader('Error')
      setMsg(
        'Para guardar la informacion, favor de proporcionar un correo o usuario'
      )
      setShowMsg(true)
      return
    }
    const data: I1868CatResponsablesCorreos = {
      id: IDCorreo,
      idResponsable: IDResponsable,
      usuario: Usuario,
      correo: Correo,
      activo: true,
    }
    CatResponsablesDS.AppendData(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        setShowMsg(true)
        loadEmails(IDResponsable)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveTransfer = () => {
    if (NuevoResponsable.length === 0) {
      setHeader('Error')
      setMsg('Favor de proporcionar el nuevo responsable para poder continuar')
      setShowMsg(true)
      return
    }
    const data: I1868CatReponsables = {
      id: IDResponsable,
      padre: -1,
      responsable: NuevoResponsable,
      usuario: '',
      activo: 1,
    }
    FService.appendCatResponsables(data)
      .then((response) => {
        if (IDResponsable === 0) dispatch(addCatResponsables(response.data))
        else dispatch(updateCatResponsables(response.data))
        setIDResponsable(response.data.id)
        saveSCACCAAT(response.data.id)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveSCACCAAT = (id: number) => {
    const data: ICatTransfers = {
      id: IDTransfer,
      padre: id,
      transfer: NuevoResponsable,
      scac: SCAC,
      caat: CAAT,
      ctpat: '',
      activo: true,
    }
    FService.appendCatTransfers(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(updateCatTransfers(response.data))
        const data = mCatTransfers.filter((tra) => tra.padre === IDResponsable)
        setSCAC(data[0] ? data[0].scac : '')
        setCAAT(data[0] ? data[0].caat : '')
        setIDTransfer(data[0] ? data[0].id : 0)
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanResponsable = () => {
    setIDNuevoResponsable(0)
    setNuevoResponsable('')
    setIDResponsable(0)
    setIDTransfer(0)
    setUsuario('')
    setCorreo('')
  }

  const setResponsable = (id: any) => {
    if (isNaN(id)) id = 0
    id = parseInt(id)
    setIDResponsable(id)
    setUsuario('')
    setCorreo('')
    setIDNuevoResponsable(id)
    let x = mCatResponsables.filter((a) => {
      if (a.id === id) {
        return a
      }
    })
    setNuevoResponsable(x[0].responsable)
  }

  const loadTransferData = () => {
    FService.getCatTransfers()
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(populateCatTransfers(response.data))
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        return
      })
  }

  return (
    <div>
      <Row style={{ paddingTop: '20px' }}>
        <Col xs={3}></Col>
        <Col xs={6}>
          <Card>
            <Card.Body>
              <Alert variant='primary'>
                <Row className='align-items-center'>
                  <Col xs={6} style={{ textAlign: 'right' }}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Proporcione un nuevo transfer'
                    >
                      <Form.Control
                        type='text'
                        value={NuevoResponsable}
                        size='sm'
                        onChange={(e) => {
                          setNuevoResponsable(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={1} style={{ textAlign: 'left' }}>
                    <div
                      title='De un click aqui para limpiar formulario'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        cleanResponsable()
                      }}
                    >
                      <IconContext.Provider
                        value={{
                          color: 'orange',
                          size: '30px',
                        }}
                      >
                        <BsFillEraserFill />
                      </IconContext.Provider>
                    </div>
                  </Col>
                  <Col>
                    <FloatingLabel controlId='floatingInput' label='SCAC'>
                      <Form.Control
                        type='text'
                        value={SCAC}
                        size='sm'
                        maxLength={4}
                        onChange={(e) => {
                          setSCAC(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel controlId='floatingInput' label='CAAT'>
                      <Form.Control
                        type='text'
                        value={CAAT}
                        size='sm'
                        maxLength={4}
                        onChange={(e) => {
                          setCAAT(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col></Col>
                  <Col xs={1} style={{ textAlign: 'right' }}>
                    <div
                      title='De un click aqui para guardar nuevo responsable'
                      onClick={() => {
                        saveTransfer()
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconContext.Provider
                        value={{ color: '#002f87', size: '30px' }}
                      >
                        <AiFillSave />
                      </IconContext.Provider>
                    </div>
                  </Col>
                </Row>
              </Alert>
              <Row style={{ paddingTop: '20px' }}>
                <Col xs={3} style={{ textAlign: 'left' }}>
                  Responsable
                </Col>
                <Col xs={8}>
                  <Form.Select
                    value={IDResponsable}
                    onChange={(e) => {
                      setResponsable(e.target.value)
                    }}
                  >
                    <option value='0'>Seleccione el transportista</option>
                    {mCatResponsables
                      ? mCatResponsables.map((a) => {
                          return (
                            <option value={a.id} key={a.id}>
                              {a.responsable}
                            </option>
                          )
                        })
                      : null}
                  </Form.Select>
                </Col>
                <Col xs={1} style={{ textAlign: 'right' }}>
                  <div
                    title='De un click aqui para guardar nuevas credenciales'
                    onClick={() => {
                      saveEmail()
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconContext.Provider
                      value={{ color: '#002f87', size: '30px' }}
                    >
                      <AiFillSave />
                    </IconContext.Provider>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: '10px' }}>
                <Col xs={6}>
                  <FloatingLabel controlId='floatingInput' label='Usuario'>
                    <Form.Control
                      type='text'
                      value={Usuario}
                      size='sm'
                      onChange={(e) => {
                        setUsuario(e.target.value)
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6}>
                  <FloatingLabel controlId='floatingInput' label='Correo'>
                    <Form.Control
                      type='email'
                      value={Correo}
                      size='sm'
                      onChange={(e) => {
                        setCorreo(e.target.value)
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <DataTable
                    noHeader
                    defaultSortFieldId={'id'}
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    columns={colData}
                    data={DataCorreos.filter(function (row) {
                      return row
                    })}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}></Col>
      </Row>

      <Modal
        show={ModalDeleteEmail}
        onHide={() => setModalDeleteEmail(false)}
        size='lg'
      >
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Alert variant='warning' style={{ textAlign: 'center' }}>
                      <h6>
                        Esta seguro de eliminar este correo ({Correo})?
                        <br />
                      </h6>
                    </Alert>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <Col></Col>
                  <Col xs={1}>
                    <Button
                      variant='secondary'
                      style={{ textAlign: 'right' }}
                      onClick={() => {
                        setModalDeleteEmail(false)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                  <Col xs={8}></Col>
                  <Col xs={2}>
                    <Button
                      variant='danger'
                      onClick={() => {
                        deleteEmail()
                      }}
                    >
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

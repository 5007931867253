import http from '../../../Services/common/http-common'
import I1868CatReponsables from '../Interfaces/I1868CatResponsables'
import I1868CatResponsablesCorreos from '../Interfaces/I1868CatResponsablesCorreos'

class CatResponsablesDataServices {
  Get() {
    return http.get<I1868CatReponsables[]>(`/CatTransportistas/Get`)
  }
  GetByResponsable(id: number) {
    return http.get<I1868CatResponsablesCorreos[]>(
      `/CatTransportistas/GetByResponsable?IdResponsable=${id}`
    )
  }
  AppendData(data: I1868CatResponsablesCorreos) {
    return http.post<I1868CatResponsablesCorreos[]>(
      `/CatTransportistas/AppendData`,
      data
    )
  }
  DeleteEmail(id: number) {
    return http.delete<Boolean>(`/CatTransportistas/DeleteEmail?id=${id}`)
  }
}
export default new CatResponsablesDataServices()

import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import {
  BsEraserFill,
  BsFillEraserFill,
  BsFillPencilFill,
} from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import I1868CatDestinos from '../Interfaces/I1868CatDestinos'
import DSCatDestinos from '../Services/CatDestinos.Services'
import { RootState } from '../../../store/store'
import { updateCatDestinos } from '../../../store/features/Clientes/1868/Cat1868DestinosSlice'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { AiFillSave } from 'react-icons/ai'
import ICatDestinoCorreo from '../Interfaces/ICatDestinoCorreo'

interface IProps {}

export const Cat1868Destinos: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const mCatDestinos = useSelector(
    (state: RootState) => state.Cat1868Destinos.Cat1868Destinos
  )
  const [ID, setID] = useState(0)
  const [IdCorreo, setIdCorreo] = useState(0)
  const [Correo, setCorreo] = useState('')
  const [Lugar, setLugar] = useState('')
  const [Direccion, setDireccion] = useState('')
  const [Buscar, setBuscar] = useState('')
  const [show, setShowMsg] = useState(false)
  //const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [CatCorreos, setCatCorreos] = useState<ICatDestinoCorreo[]>([])

  const colData = [
    {
      name: '',
      width: '50px',
      cell: (row: I1868CatDestinos) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setID(row.id)
            setLugar(row.lugar)
            setDireccion(row.direccion)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '15px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Lugar',
      width: '15%',
      cell: (row: I1868CatDestinos) => (
        <div style={{ fontSize: '11px' }}>{row.lugar}</div>
      ),
    },
    {
      name: 'Direccion',
      width: '70%',
      cell: (row: I1868CatDestinos) => (
        <div style={{ fontSize: '11px' }}>{row.direccion}</div>
      ),
    },
  ]

  const colCorreos = [
    {
      name: 'id',
      width: '6%',
      selector: (row: ICatDestinoCorreo) => row.id,
      sortable: true,
    },
    {
      name: 'Correo',
      width: '70%',
      selector: (row: ICatDestinoCorreo) => row.correo,
    },
    {
      name: '',
      cell: (row: ICatDestinoCorreo) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIdCorreo(row.id)
            setCorreo(row.correo)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '15px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const deleteItem = (id: number) => {
    alert('Delete item ' + id)
  }

  const save = () => {
    const data: I1868CatDestinos = {
      id: ID,
      lugar: Lugar ? Lugar : '',
      direccion: Direccion ? Direccion : '',
    }
    DSCatDestinos.appendCatDestino(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(updateCatDestinos(response.data))
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanForm = () => {
    setID(0)
    setLugar('')
    setDireccion('')
  }

  const saveEmail = () => {
    const data: ICatDestinoCorreo = {
      id: IdCorreo,
      idDestino: ID,
      correo: Correo,
    }
    DSCatDestinos.CorreoAppend(data)
      .then((response) => {
        loadEmails()
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadEmails = () => {
    DSCatDestinos.CorreosGet()
      .then((response) => {
        setCatCorreos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanEmail = () => {
    setIdCorreo(0)
    setCorreo('')
  }

  useEffect(() => {
    if (ID > 0) loadEmails()
  }, [ID])

  return (
    <div>
      <Row>
        <Col xs={9}>
          <Card>
            <Row>
              <Col xs={1} style={{ textAlign: 'right' }}>
                Lugar
              </Col>
              <Col xs={3}>
                <Form.Control
                  type='text'
                  id='Lugar'
                  value={Lugar}
                  size='sm'
                  onChange={(e) => {
                    setLugar(e.target.value)
                  }}
                />
              </Col>
              <Col xs={1}>
                <Button
                  variant='warning'
                  size='sm'
                  onClick={() => {
                    cleanForm()
                  }}
                >
                  Limpiar
                </Button>
              </Col>
              <Col xs={1}></Col>
              <Col xs={1} style={{ textAlign: 'right' }}>
                <Button variant='primary' onClick={() => save()} size='sm'>
                  Guardar
                </Button>
              </Col>
            </Row>
            <Row style={{ paddingTop: '5px' }}>
              <Col xs={1} style={{ textAlign: 'right' }}>
                Destino
              </Col>
              <Col xs={10}>
                <Form.Control
                  type='text'
                  id='Direccion'
                  value={Direccion}
                  size='sm'
                  onChange={(e) => {
                    setDireccion(e.target.value)
                  }}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '5px' }}>
              <Col xs={1}>&nbsp;</Col>
              <Col xs={10}>
                <Form.Control
                  type='text'
                  id='Buscar'
                  value={Buscar}
                  placeholder='Search...'
                  size='sm'
                  onChange={(e) => {
                    setBuscar(e.target.value)
                  }}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '5px' }}>
              <Col xs={12}>
                <DataTable
                  noHeader
                  defaultSortFieldId={'id'}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  dense
                  columns={colData}
                  data={mCatDestinos.filter(function (row) {
                    return (
                      row.lugar.toLowerCase().includes(Buscar.toLowerCase()) ||
                      row.direccion.toLowerCase().includes(Buscar.toLowerCase())
                    )
                  })}
                  customStyles={{
                    headRow: {
                      style: {
                        color: '#223336',
                        backgroundColor: '#e7eef0',
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={3}>
          <Card>
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <div
                  title='De un click aqui para limpiar formulario'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    cleanEmail()
                  }}
                >
                  <IconContext.Provider
                    value={{
                      color: 'orange',
                      size: '30px',
                    }}
                  >
                    <BsFillEraserFill />
                  </IconContext.Provider>
                </div>
              </Col>
              <Col xs={8}></Col>
              <Col>
                <div
                  title='De un click aqui para guardar nuevo responsable'
                  onClick={() => {
                    saveEmail()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <IconContext.Provider
                    value={{ color: '#002f87', size: '30px' }}
                  >
                    <AiFillSave />
                  </IconContext.Provider>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FloatingLabel
                  controlId='floatingInput'
                  label='Correo'
                  className='mb-3'
                >
                  <Form.Control
                    type='email'
                    placeholder='correo@empresa.com'
                    value={Correo}
                    onChange={(e) => setCorreo(e.target.value)}
                    disabled={ID === 0}
                  />
                </FloatingLabel>
              </Col>
              <Col style={{ textAlign: 'left' }}></Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DataTable
                  noHeader
                  defaultSortFieldId={'id'}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  dense
                  columns={colCorreos}
                  data={CatCorreos.filter(function (row) {
                    return row.idDestino === ID
                  })}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import I1868CatResponsables from '../../../../Components/Cliente/Interfaces/I1868CatResponsables'
const Cat1868Responsables: I1868CatResponsables[] = [
  {
    id: 0,
    padre: 0,
    responsable: '- Seleccione responsable -',
    usuario: '',
    activo: 1,
  },
]
const initialState = { Cat1868Responsables }

export const Cat1868ResponsablesSlice = createSlice({
  name: 'CatResponsables',
  initialState: initialState,
  reducers: {
    populateCatResponsables: (
      state,
      action: PayloadAction<I1868CatResponsables[]>
    ) => {
      state.Cat1868Responsables = []
      state.Cat1868Responsables.push(...action.payload)
    },
    addCatResponsables: (
      state,
      action: PayloadAction<I1868CatResponsables>
    ) => {
      var Existe = state.Cat1868Responsables.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.Cat1868Responsables.push(action.payload)
    },
    updateCatResponsables: (
      state,
      action: PayloadAction<I1868CatResponsables>
    ) => {
      const i = state.Cat1868Responsables.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.Cat1868Responsables[i] = action.payload
      else state.Cat1868Responsables.push(action.payload)
    },
    deleteCatResponsables: (state, action: PayloadAction<number>) => {
      const newArr = state.Cat1868Responsables.filter(
        (data) => data.id != action.payload
      )
      state.Cat1868Responsables = newArr
    },
  },
})

export const {
  addCatResponsables,
  populateCatResponsables,
  updateCatResponsables,
  deleteCatResponsables,
} = Cat1868ResponsablesSlice.actions
export default Cat1868ResponsablesSlice.reducer

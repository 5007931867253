import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useEffect, useState } from 'react'
import CatResponsablesDS from '../../Cliente/Services/CatResponsables.Services'
import KPIDS from '../../Cliente/Services/KPI.Services'
import { populateCatResponsables } from '../../../store/features/Clientes/1868/Cat1868ResponsablesSlice'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import IKPICruces from '../../Cliente/Interfaces/IKPICruces'

export interface IKPICrucesProps {}

export default function KPICruces(props: IKPICrucesProps) {
  const dispatch = useDispatch()
  const mCatResponsables = useSelector(
    (state: RootState) => state.Cat1868Responsables.Cat1868Responsables
  )
  const [IDTransfer, setIDTransfer] = useState(0)
  const [Transfer, setTransfer] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-7))
  const [Fin, setFin] = useState(currentDate(0))
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [Data, setData] = useState<IKPICruces[]>([])
  const [series, setSeries] = useState([
    {
      type: 'line', //render a line chart for this data
      name: 'Horas',
      data: [0],
    },
    {
      type: 'column', //use column chart here.
      name: 'Dias',
      data: [0],
    },
  ])
  const [options, setOptions] = useState<ApexOptions>({
    xaxis: {
      categories: [''],
    },
    chart: {
      height: 100,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
  })

  const setResponsable = (id: any) => {
    if (isNaN(id)) id = 0
    id = parseInt(id)
    setIDTransfer(id)
    let x = mCatResponsables.filter((a) => {
      if (a.id === id) {
        return a
      }
    })
  }

  const loadCatResponsables = () => {
    CatResponsablesDS.Get()
      .then((response) => {
        dispatch(populateCatResponsables(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadData = () => {
    KPIDS.TiempoCrucesGet(Inicio, Fin, IDTransfer)
      .then((response) => {
        console.log(response.data)
        setData(response.data)
        let Invoices: string[] = []
        let Horas: number[] = []
        let Dias: number[] = []
        response.data.forEach(function (item) {
          Invoices.push(item.invoice)
          Horas.push(item.horas)
          Dias.push(item.dias)
        })
        setSeries([
          {
            type: 'line', //render a line chart for this data
            name: 'Horas',
            data: Horas,
          },
          {
            type: 'column', //use column chart here.
            name: 'Dias',
            data: Dias,
          },
        ])
        setOptions({
          xaxis: {
            categories: Invoices,
          },
          chart: {
            height: 100,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
            dropShadow: {
              enabled: true,
              left: 2,
              top: 2,
              opacity: 0.5,
            },
          },
          grid: {
            position: 'front',
            borderColor: '#AAA',
            strokeDashArray: 3,
          },
          legend: {
            show: true,
            position: 'top',
          },
        })
        var arrTransfers = mCatResponsables.filter((item) => {
          return item.id == IDTransfer
        })
        setTransfer(arrTransfers[0].responsable)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadCatResponsables()
  }, [])

  return (
    <>
      <div style={{ paddingTop: '20px' }}>
        <Card>
          <Row style={{ padding: '10px' }}>
            <Col xs={1}></Col>
            <Col xs={4}>
              <Form.Select
                value={IDTransfer}
                onChange={(e) => {
                  setResponsable(e.target.value)
                }}
              >
                <option value='0'>Seleccione el transportista</option>
                {mCatResponsables
                  ? mCatResponsables.map((a) => {
                      return (
                        <option value={a.id} key={a.id}>
                          {a.responsable}
                        </option>
                      )
                    })
                  : null}
              </Form.Select>
            </Col>
            <Col xs={1}></Col>
            <Col style={{ textAlign: 'right' }}>Inicio</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                disabled={IDTransfer == 0}
                size='sm'
              />
            </Col>
            <Col style={{ textAlign: 'right' }}>Fin</Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                disabled={IDTransfer == 0}
                size='sm'
              />
            </Col>
            <Col xs={1}></Col>
            <Col>
              <Button
                variant='primary'
                disabled={IDTransfer == 0}
                onClick={() => {
                  loadData()
                }}
              >
                Generar
              </Button>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Card>
        <Card style={{visibility: IDTransfer > 0 ? 'visible' : 'hidden'}}>
          <Card.Body>
            <Card.Title>
              {Transfer}
              <br />
              <br />
            </Card.Title>
            <ReactApexChart
              options={options}
              series={series}
              width='100%'
              height='600px'
            />
          </Card.Body>
        </Card>
      </div>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}

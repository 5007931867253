import http from '../../../Services/common/http-common'
import ICatNotificaciones from '../Interfaces/ICatNotificaciones'

class CatNotificacionesDataServices {
  Get() {
    return http.get<ICatNotificaciones[]>(
      `/CatNotificaciones/CatNotificaciones/Get`
    )
  }
  Append(data: ICatNotificaciones) {
    return http.post<ICatNotificaciones>(
      `/CatNotificaciones/CatNotificaciones/Append`,
      data
    )
  }
  DeleteEmail(id: number) {
    return http.delete<Boolean>(
      `/CatTransportistas/CatNotificaciones/DeleteEmail?id=${id}`
    )
  }
}
export default new CatNotificacionesDataServices()

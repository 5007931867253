import http from '../../../Services/common/http-common'
import IConfigEmails from '../Interfaces/IConfigEmails'

class ConfigDataServices {
  EmailNotifyGET() {
    return http.get<IConfigEmails>(`/Config/EmailNotifyGet`)
  }
  EmailNotifyUpdate(data: IConfigEmails) {
    return http.post<boolean>(`/Config/EmailNotifyUpdate`,data)
  }  
}
export default new ConfigDataServices()

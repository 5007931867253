import http from '../../../Services/common/http-common'
import IKPIAgenteAduanal from '../Interfaces/IKPIAgenteAduanal'
import IKPICruces from '../Interfaces/IKPICruces'
import IKPICrucesTotales from '../Interfaces/IKPICrucesTotales'

class KPIDataServices {
  TiempoCrucesGet(Inicio: string, Fin: string, Transfer: number) {
    return http.get<IKPICruces[]>(`/KPIs/getKPICruces?Inicio=${Inicio}&Fin=${Fin}&Transfer=${Transfer}`)
  }
  CrucesTotalesGet(Inicio: string, Fin: string) {
    return http.get<IKPICrucesTotales[]>(`/KPIs/getKPICrucesTotales?Inicio=${Inicio}&Fin=${Fin}`)
  }
  TiempoAgenteAduanalGet(Inicio: string, Fin: string) {
    return http.get<IKPIAgenteAduanal[]>(`/KPIs/getKPIAgenteAduanal?Inicio=${Inicio}&Fin=${Fin}`)
  }
}
export default new KPIDataServices()
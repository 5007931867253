import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import CatResponsables from './Components/Cliente/Catalogos/CatResponsables'
import Config from './Components/Cliente/Catalogos/Config'
import Dashboard from './Components/Dashboard/Dashboard'
import Help from './Components/Help/Help'
import Login from './Components/Login/Login'
import Logout from './Components/Logout/logout'
import RptFacturas from './Components/Cliente/Reportes/Facturas/RptFacturas'
import RptTransportista from './Components/Cliente/Reportes/Transportistas/RtpTransportista'
import ResetCredentials from './Components/Login/ResetCredentials'
import RptBroker from './Components/Cliente/Reportes/Broker/RptBroker'
import Register from './Components/Register/register'
import UserControl from './Components/UserControl/UserControl'
import RptViajesTerminados from './Components/Cliente/Reportes/ViajesTerminados/RptViajesTerminados'
import ForgotPassword from './Components/Login/ForgotPassword'
import PIN from './Components/Login/PIN'
import CatTransfers from './Components/Cliente/Catalogos/CatTransfers'
import KPICruces from './Components/Dashboard/KPIs/KPICruces'
import KPICrucesTotales from './Components/Dashboard/KPIs/KPICrucesTotales'
import CatNotificaciones from './Components/Cliente/Catalogos/CatNotificaciones'
import { KPIAgenteAduanal } from './Components/Dashboard/KPIs/KPIAgenteAduanal'
import CatTransfersZ from './Components/Cliente/Catalogos/CatTransfersZ'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function PageNotFound() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '300px' }}>
      <h2>Sorry, no matching page</h2>
    </div>
  )
}

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path='/' element={<App />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/PIN' element={<PIN />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/help' element={<Help />} />
            <Route path='/Register' element={<Register />} />
            <Route path='/RptFacturas' element={<RptFacturas />} />
            <Route path='/CatResponsables' element={<CatResponsables />} />
            <Route path='/RptTransportista' element={<RptTransportista />} />
            <Route path='/RptBroker' element={<RptBroker />} />
            <Route path='/CatTransfers' element={<CatTransfers />} />
            <Route path='/CatTransfersZ' element={<CatTransfersZ />} />
            <Route
              path='/RptViajesTerminados'
              element={<RptViajesTerminados />}
            />
            <Route path='/KPICruces' element={<KPICruces />} />
            <Route path='/KPICrucesTotales' element={<KPICrucesTotales />} />
            <Route path='/KPIAgenteAduanal' element={<KPIAgenteAduanal />} />
            <Route path='/CatNotificaciones' element={<CatNotificaciones />} />
            <Route path='/UserControl' element={<UserControl />} />
            <Route path='/Reset' element={<ResetCredentials />} />
            <Route path='/Config' element={<Config />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </Provider>
)
reportWebVitals()

import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import {
  BsCheckCircleFill,
  BsEraserFill,
  BsFillPencilFill,
  BsXCircleFill,
} from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import FService from '../Services/Facturas.Services'
import { RootState } from '../../../store/store'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import ICatTransfers from '../Interfaces/ICatTransfers'
import {
  populateCatTransfers,
  updateCatTransfers,
} from '../../../store/features/Clientes/1868/CatTransfersSlice'

export interface ICatTransfersProps {}

export default function CatTransfers(props: ICatTransfersProps) {
  const dispatch = useDispatch()
  const mCatTransfers = useSelector(
    (state: RootState) => state.CatTransfers.CatTransfers
  )
  const [ID, setID] = useState(0)
  const [Lugar, setLugar] = useState('')
  const [Direccion, setDireccion] = useState('')
  const [Buscar, setBuscar] = useState('')
  const [Transfer, setTransfer] = useState('')
  const [SCAC, setSCAC] = useState('')
  const [CAAT, setCAAT] = useState('')
  const [Activo, setActivo] = useState(false)
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const colData = [
    {
      name: 'id',
      width: '6%',
      selector: (row: ICatTransfers) => row.id,
      sortable: true,
    },
    {
      name: 'Transfer',
      width: '30%',
      selector: (row: ICatTransfers) => row.transfer,
    },
    {
      name: 'SCAC',
      width: '120px',
      selector: (row: ICatTransfers) => row.scac,
    },
    {
      name: 'CAAT',
      width: '120px',
      selector: (row: ICatTransfers) => row.caat,
    },
    {
      name: 'Activo',
      width: '80px',
      cell: (row: ICatTransfers) => (
        <>
          <IconContext.Provider
            value={{ color: row.activo ? 'green' : 'red', size: '20px' }}
          >
            {row.activo ? <BsCheckCircleFill /> : <BsXCircleFill />}
          </IconContext.Provider>
        </>
      ),
    },
    {
      name: 'Edita',
      cell: (row: ICatTransfers) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setID(row.id)
            setTransfer(row.transfer)
            setSCAC(row.scac)
            setCAAT(row.caat)
            setActivo(row.activo)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const deleteItem = (id: number) => {
    alert('Delete item ' + id)
  }

  const save = () => {
    const data: ICatTransfers = {
      id: ID,
      padre: 0,
      transfer: Transfer,
      scac: SCAC,
      caat: CAAT,
      ctpat: '',
      activo: Activo,
    }
    FService.appendCatTransfers(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(updateCatTransfers(response.data))
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanForm = () => {
    setID(0)
    setTransfer('')
    setSCAC('')
    setCAAT('')
  }

  const loadTransferData = () => {
    FService.getCatTransfers()
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        dispatch(populateCatTransfers(response.data))
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        return
      })
  }

  useEffect(() => {
    loadTransferData()
  }, [])

  return (
    <div>
      <Row style={{ paddingTop: '10px' }}></Row>
      <Card style={{ paddingTop: '10px' }}>
        <Card.Body>
          <Row>
            <Col xs={1}>Transfer</Col>
            <Col xs={3}>
              <Form.Control
                type='text'
                id='Transfer'
                value={Transfer}
                size='sm'
                onChange={(e) => {
                  setTransfer(e.target.value)
                }}
              />
            </Col>
            <Col xs={1}>SCAC</Col>
            <Col xs={1}>
              <Form.Control
                type='text'
                id='SCAC'
                value={SCAC}
                size='sm'
                onChange={(e) => {
                  setSCAC(e.target.value)
                }}
              />
            </Col>
            <Col xs={1}>CAAT</Col>
            <Col xs={1}>
              <Form.Control
                type='text'
                id='CAAT'
                value={CAAT}
                size='sm'
                onChange={(e) => {
                  setCAAT(e.target.value)
                }}
              />
            </Col>
            <Col>
              <Form.Control
                as='select'
                value={Activo ? '1' : '0'}
                onChange={(e) => {
                  setActivo(e.target.value === '1')
                }}
                className='form-select form-select-sm'
              >
                <option value='1'>Activo</option>
                <option value='0'>INACTIVO</option>
              </Form.Control>
            </Col>
            <Col xs={1}>
              <Button
                variant='warning'
                size='sm'
                onClick={() => {
                  cleanForm()
                }}
              >
                <BsEraserFill />
                &nbsp;Limpiar
              </Button>
            </Col>
            <Col xs={1}></Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              <Button variant='primary' onClick={() => save()} size='sm'>
                Guardar
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row style={{ paddingTop: '5px' }}>
        <Col xs={12}>
          <Form.Control
            type='text'
            id='Buscar'
            value={Buscar}
            placeholder='Search...'
            size='sm'
            onChange={(e) => {
              setBuscar(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: '5px' }}>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            highlightOnHover
            columns={colData}
            data={mCatTransfers.filter(function (row) {
              return (
                row.transfer.toLowerCase().includes(Buscar.toLowerCase()) ||
                row.scac.toLowerCase().includes(Buscar.toLowerCase()) ||
                row.caat.toLowerCase().includes(Buscar.toLowerCase())
              )
            })}
          />
        </Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}

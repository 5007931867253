import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import I1868FacturaDetalle from '../../../../Components/Cliente/Interfaces/I1868FacturaDetalle'
const Data1868FacturaDetalle: I1868FacturaDetalle[] = []
const initialState = { Data1868FacturaDetalle }

export const Data1868FacturaDetalleSlice = createSlice({
  name: 'Data1868FacturaDetalle',
  initialState: initialState,
  reducers: {
    populateFacturaDetalle : (state, action: PayloadAction<I1868FacturaDetalle[]>) => {
      action.payload.forEach(newItem => {
        var Existe = state.Data1868FacturaDetalle.find(function(onMemoryItem) {
            return onMemoryItem.id === newItem.id;
          });
         if (!Existe) state.Data1868FacturaDetalle.push(newItem)
      })
      state.Data1868FacturaDetalle.forEach(onMemory=> {
       onMemory.minimizado=false;
      })
    },
    addFacturaDetalle : (state, action: PayloadAction<I1868FacturaDetalle>) => {
      var Existe = state.Data1868FacturaDetalle.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.Data1868FacturaDetalle.push(action.payload)
    },
    updateFacturaDetalle : (state, action: PayloadAction<I1868FacturaDetalle>) => {
      const i = state.Data1868FacturaDetalle.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.Data1868FacturaDetalle[i] = action.payload;
      else state.Data1868FacturaDetalle.push(action.payload);
    },
    deleteFacturaDetalle : (state, action: PayloadAction<number>) => {
      const newArr = state.Data1868FacturaDetalle.filter(data => data.id != action.payload);
      state.Data1868FacturaDetalle=newArr
    },
    toggleRow : (state, action: PayloadAction<number>) => {
        //const childs4Toggle = state.Data1868FacturaDetalle.filter(data => data.idFactura != action.payload);
        state.Data1868FacturaDetalle.forEach(onMemory=> {
            if (onMemory.idFactura===action.payload) {
            onMemory.minimizado=!onMemory.minimizado
            }
        })
    },
  },
})

export const { addFacturaDetalle, populateFacturaDetalle, updateFacturaDetalle, deleteFacturaDetalle, toggleRow } = Data1868FacturaDetalleSlice.actions;
export default Data1868FacturaDetalleSlice.reducer;

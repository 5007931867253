import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsFillPencilFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import DTO1868FacturaDetalle from '../../DTO/DTO1868FacturaDetalle'
import DTO1868ReporteFactura from '../../DTO/DTO1868ReporteFactura'
import I1868CatClaveSAT from '../../Interfaces/I1868CatClaveSAT'
import I1868FacturaDetalle from '../../Interfaces/I1868FacturaDetalle'
import FService from '../../Services/Facturas.Services'
import { RootState } from '../../../../store/store'
import { updateFacturaDetalle } from '../../../../store/features/Clientes/1868/Data1868FacturaDetalleSlice'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'

export interface IRptViajesTerminadosDetalleProps {
    DataMaster: DTO1868ReporteFactura
}

export default function RptViajesTerminadosDetalle (props: IRptViajesTerminadosDetalleProps) {
    const dispatch = useDispatch()
    const mDataFacturaDetalle = useSelector(
      (state: RootState) => state.Data1868FacturaDetalle.Data1868FacturaDetalle
    )
    const mCatClaveSAT = useSelector(
      (state: RootState) => state.Cat1868ClaveSAT.Cat1868ClaveSAT
    )
    const [UserType, setUserType] = useState(() => {
      const stickyValue = window.localStorage.getItem('UserType')
      return stickyValue !== null ? JSON.parse(stickyValue) : ''
    })
    const [IDDetail, setIDDetail] = useState(0)
    const [ClaveSAT, setClaveSAT] = useState('-SELECCIONE-')
    const [Empaque, setEmpaque] = useState('')
    const [modalEditDetail, setModalEditDetail] = useState(false)
    const [DisplayTable, setDisplayTable] = useState(true)
    const [DataCatSAT, setDataCatSAT] = useState<I1868CatClaveSAT[]>([])
    const [show, setShowMsg] = useState(false)
    const [ShowModal, setShowModal] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [msgColor, setMsgColor] = useState('primary')
  
    const EditDetail = (f: DTO1868ReporteFactura, row: I1868FacturaDetalle) => {
      setIDDetail(row.id)
      const Clave = row.claveSAT2
      setClaveSAT(Clave)
      var resultSAT = mCatClaveSAT.filter((row) => row.clave.includes(Clave))
      setEmpaque(row.empaqueDescripcion)
      setModalEditDetail(true)
    }
  
    useEffect(() => {
      mDataFacturaDetalle.forEach((onMemory) => {
        if (onMemory.idFactura === props.DataMaster.id)
          setDisplayTable(onMemory.minimizado)
      })
    }, [mDataFacturaDetalle])
  
    const saveDetail = () => {
      if (ClaveSAT === 'CLAVE SAT') {
        setModalEditDetail(false)
        setHeader('Error')
        setMsg(
          'Para poder guardar la informacion, seleccione la clave SAT correspondiente'
        )
        setShowMsg(true)
        return false
      }
      const data: DTO1868FacturaDetalle = {
        id: IDDetail,
        claveSAT: ClaveSAT ? ClaveSAT : '',
        empaque: Empaque,
      }
      FService.UpdateDetail(data)
        .then((response) => {
          setHeader(response.data ? 'Informativo' : 'Error')
          setMsg(
            response.data
              ? 'La informacion se guardo correctamente'
              : 'Ocurrio un error, no se guardo la informacion'
          )
          dispatch(updateFacturaDetalle(response.data))
          setModalEditDetail(false)
          setShowMsg(true)
          return
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  
    const changeClaveSAT = (value: string) => {
      setClaveSAT(value)
      var result = mCatClaveSAT.filter((row) => row.clave.includes(value))
      setEmpaque(result[0] ? result[0].descripcion : '')
    }
  
    return (
      <div>
        <table className='zui-table zui-table-rounded' hidden={!DisplayTable}>
          <thead>
            <tr>
              <th style={{ width: '50px', textAlign: 'center' }}>Partida</th>
              <th style={{ width: '100px', textAlign: 'right' }}>Peso bruto</th>
              <th style={{ width: '100px', textAlign: 'right' }}>Pallets</th>
              <th style={{ width: '250px', textAlign: 'left' }}>
                Producto Materia Prima
              </th>
              <th style={{ width: '180px' }}>Fraccion Arancelaria</th>
              <th style={{ width: '100px' }}>Clave SAT</th>
              <th style={{ width: '250px' }}>Concepto Producto MP</th>
              {/* <th style={{ width: '50px', textAlign: 'center' }}>Edita</th> */}
              <th style={{ width: '300px' }}>Empaque (descripcion)</th>
              <th style={{ width: '100px' }}>Clave SAT</th>
            </tr>
          </thead>
          {mDataFacturaDetalle
            ? mDataFacturaDetalle.map((td) => {
                if (td.idFactura === props.DataMaster.id)
                  return (
                    <tbody style={{ fontWeight: 'normal' }}>
                      <tr style={{}}>
                        <td
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.partida}
                        </td>
                        <td
                          style={{
                            textAlign: 'right',
                            paddingRight: '10px',
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.pesoBruto}
                        </td>
                        <td
                          style={{
                            textAlign: 'right',
                            paddingRight: '10px',
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.totalBultos}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.productoMateriaPrima}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.fraccionArancelaria}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.claveSAT1}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.conceptoProductoMP}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.empaqueDescripcion}
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              td.partida === 2 ? '#F0F7FC' : '#FFFFFF',
                          }}
                        >
                          {td.claveSAT2}
                        </td>
                      </tr>
                    </tbody>
                  )
              })
            : ''}
        </table>
        <Modal
          show={modalEditDetail}
          onHide={() => setModalEditDetail(false)}
          size='lg'
        >
          <Modal.Body>
            <Card className='labelSize13px'>
              <Card.Body>
                <Card>
                  <Row>
                    <Col xs={12}>
                      <Alert variant='primary' style={{ textAlign: 'center' }}>
                        <h6>
                          Favor de proporcionar la siguiente informacion para
                          complementar la factura [{props.DataMaster.invoice}]
                          <br />
                        </h6>
                      </Alert>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Col xs={2}>
                      <Form.Control
                        as='select'
                        value={ClaveSAT}
                        onChange={(e) => {
                          changeClaveSAT(e.target.value)
                        }}
                        className='form-select form-select-sm'
                      >
                        <option value='0'>CLAVE SAT</option>
                        {mCatClaveSAT
                          ? mCatClaveSAT.map((a) => {
                              return (
                                <option value={a.clave} key={a.id}>
                                  {a.clave}
                                </option>
                              )
                            })
                          : null}
                      </Form.Control>
                    </Col>
                    <Col xs={10}>
                      <Form.Control
                        type='text'
                        id='Empaque'
                        value={Empaque}
                        disabled
                        size='sm'
                        onChange={(e) => {
                          setEmpaque(e.target.value)
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={10}>&nbsp;</Col>
              <Col xs={2}>
                <Button variant='primary' onClick={() => saveDetail()} size='sm'>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
        <MsgInformativo
          show={show}
          msg={msg}
          header={header}
          time={2000}
          msgColor={msgColor}
          closeToast={() => {
            setShowMsg(false)
          }}
        />
      </div>
    )
  }
  
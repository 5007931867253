import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatNotificaciones from '../../../../Components/Cliente/Interfaces/ICatNotificaciones'
const CatNotificaciones: ICatNotificaciones[] = []
const initialState = { CatNotificaciones }

export const CatNotificacionesSlice = createSlice({
  name: 'CatNotificaciones',
  initialState: initialState,
  reducers: {
    populateCatNotificaciones: (state, action: PayloadAction<ICatNotificaciones[]>) => {
      action.payload.forEach((newItem) => {
        var Existe = state.CatNotificaciones.find(function (onMemoryItem) {
          return onMemoryItem.id === newItem.id
        })
        if (!Existe) state.CatNotificaciones.push(newItem)
      })
    },
    addCatNotificaciones: (state, action: PayloadAction<ICatNotificaciones>) => {
      var Existe = state.CatNotificaciones.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatNotificaciones.push(action.payload)
    },
    updateCatNotificaciones: (state, action: PayloadAction<ICatNotificaciones>) => {
      const i = state.CatNotificaciones.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatNotificaciones[i] = action.payload
      else state.CatNotificaciones.push(action.payload)
    },
    deleteCatNotificaciones: (state, action: PayloadAction<number>) => {
      const newArr = state.CatNotificaciones.filter(
        (data) => data.id != action.payload
      )
      state.CatNotificaciones = newArr
    },
  },
})

export const {
  addCatNotificaciones,
  populateCatNotificaciones,
  updateCatNotificaciones,
  deleteCatNotificaciones,
} = CatNotificacionesSlice.actions
export default CatNotificacionesSlice.reducer

import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface IHelpProps {}

export default function Help(props: IHelpProps) {
  const navigate = useNavigate()
  const [UserType, setUserType] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserType')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Try, setTry] = useState(1)

  const Between = (x:number, min:number, max:number) : boolean => {
    return x >= min && x <= max;
  }

  useEffect(() => {
    if (UserType === '0') {
      window.open('https://zinc.gemcousa.mx/docs/DOCT.pdf', '_blank')
    } else if (Between(parseInt(UserType),1,3)) {
      window.open('https://zinc.gemcousa.mx/docs/DOCC.pdf', '_blank')
      window.open('https://zinc.gemcousa.mx/docs/DOCB.pdf', '_blank')
      window.open('https://zinc.gemcousa.mx/docs/DOCT.pdf', '_blank')
    } else {
      window.open('https://zinc.gemcousa.mx/docs/DOCB.pdf', '_blank')
    }
    navigate('/')
  }, [])

  return <div></div>
}

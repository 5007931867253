import { configureStore } from '@reduxjs/toolkit'
import userStatusReducer from './features/userStatusSlice/userStatusSlice'
import Cat1868DestinosSlice from './features/Clientes/1868/Cat1868DestinosSlice'
import Cat1868ResponsablesSlice from './features/Clientes/1868/Cat1868ResponsablesSlice'
import Cat1868ClaveSATSlice from './features/Clientes/1868/Cat1868ClaveSATSlice'
import Data1868FacturaDetalleSlice from './features/Clientes/1868/Data1868FacturaDetalleSlice'
import CatTransfersSlice from './features/Clientes/1868/CatTransfersSlice'
import CatNotificacionesSlice from './features/Clientes/1868/CatNotificaciones'


const store = configureStore({
  reducer: {
    userStatus: userStatusReducer,
    Cat1868Destinos: Cat1868DestinosSlice,
    Cat1868Responsables: Cat1868ResponsablesSlice,
    Cat1868ClaveSAT: Cat1868ClaveSATSlice,
    Data1868FacturaDetalle: Data1868FacturaDetalleSlice,
    CatTransfers: CatTransfersSlice,
    CatNotificaciones: CatNotificacionesSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store